import { Card, CardDescription, CardTitle } from '@clubsoul/ui';
import { cn } from '@clubsoul/ui/lib/utils';
import { Link } from '@tanstack/react-router';

import { usePurchases } from '@/api/purchase/usePurchases';

import { PurchaseItem, PurchasePresentation } from './purchase-item';

type PurchasesListProps = {
  presentation: PurchasePresentation;
};

export function PurchasesList(props: PurchasesListProps) {
  const { presentation } = props;
  const isEmpty = false;

  const data = usePurchases();

  if (isEmpty) {
    return (
      <Card className="space-y-4 px-4 py-8 text-center">
        <CardTitle className="leading-8">Keine Abgabehistorie</CardTitle>
        <CardDescription>
          Zurzeit ist keine Abgabehistorie vorhanden.
        </CardDescription>
      </Card>
    );
  }

  if (presentation === PurchasePresentation.SIMPLE) {
    return (
      <Card className="p-4">
        <ul className={cn('flex flex-col divide-y')}>
          {data.map((purchase) => (
            <li key={purchase.id} className={cn('py-2 first:pt-0 last:pb-0')}>
              <Link to="/purchases/$id" params={{ id: purchase.id }}>
                <PurchaseItem {...purchase} presentation={presentation} />
              </Link>
            </li>
          ))}
        </ul>
      </Card>
    );
  }

  return (
    <ul className={cn('flex flex-col gap-4')}>
      {data.map((purchase) => (
        <li key={purchase.id}>
          <Link to="/purchases/$id" params={{ id: purchase.id }}>
            <Card className="p-4 hover:border-green-500">
              <PurchaseItem {...purchase} presentation={presentation} />
            </Card>
          </Link>
        </li>
      ))}
    </ul>
  );
}
