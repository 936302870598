import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import AuthLayout from '@/auth/auth-layout';
import ForgotPasswordForm from '@/auth/forgot-password-form';
import { Page, PageContent } from '@/components/layout';

export const Route = createFileRoute('/_auth/forgot-password')({
  component: ForgotPasswordPage,
});

function ForgotPasswordPage() {
  return (
    <AuthLayout>
      <Page>
        <PageContent className="mt-8 rounded-t-md bg-white px-4">
          <Card>
            <CardHeader>
              <CardTitle>Passwort zurücksetzen</CardTitle>
              <CardDescription>
                Gib deine E-Mail-Adresse ein, und wir schicken dir eine E-Mail
                zum Zurücksetzen deines Passworts.
              </CardDescription>
            </CardHeader>

            <CardContent>
              <ForgotPasswordForm />
            </CardContent>
          </Card>
        </PageContent>
      </Page>
    </AuthLayout>
  );
}
