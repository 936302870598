import { Suspense } from 'react';

import { MEMBERSHIPS_ENABLED, PURCHASES_ENABLED } from '@clubsoul/const';
import { Avatar, AvatarFallback, AvatarImage } from '@clubsoul/ui';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Link, createFileRoute } from '@tanstack/react-router';

import { clubDetailsQueryOptions } from '@/api/club/clubQueryOptions';
import useUser from '@/auth/useUser';
import ClubCard from '@/components/club-card';
import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { LinkButton } from '@/components/link-button';
import { MembershipsList } from '@/components/memberships';
import { PurchasePresentation, PurchasesList } from '@/components/purchases';
import { auth } from '@/lib/firebase';

export const Route = createFileRoute('/_app/')({
  loader: async ({ context }) => {
    await auth.authStateReady();

    if (context.auth.session?.clubId) {
      return context.queryClient.ensureQueryData(clubDetailsQueryOptions());
    }
  },
  component: HomeComponent,
});

function HomeComponent() {
  const { firstName, lastName, clubId, profilePic } = useUser() || {};

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="flex items-center justify-between">
        <div>
          <span>Hallo!</span>
          <PageTitle>
            {firstName} {lastName}
          </PageTitle>
        </div>

        <Link to="/profile">
          <Avatar className="h-12 w-12">
            <AvatarImage src={profilePic} />
            <AvatarFallback>
              {firstName?.charAt(0)} {lastName?.charAt(0)}
            </AvatarFallback>
          </Avatar>
        </Link>
      </PageHeader>

      {/* <PageContent>
        <Alert variant={'info'}>
          <AlertDescription className="text-center">
            Nächste Zahlung ist fällig am: XX.XX.XXXX
          </AlertDescription>
        </Alert>
      </PageContent> */}

      <Suspense>{clubId && <ClubDetails />}</Suspense>

      {MEMBERSHIPS_ENABLED.enabled && <MembershipsSection />}

      {PURCHASES_ENABLED.enabled && <PurchasesSection />}
    </div>
  );
}

function ClubDetails() {
  const {
    data: { name, logoUrl, membersSize },
  } = useSuspenseQuery(clubDetailsQueryOptions());

  return (
    <PageContent className="space-y-2">
      <PageContentTitle>Dein Club</PageContentTitle>

      <div>
        <Link to="/club">
          {/* <Card className="p-4 text-center">
            <div className="mb-4 flex justify-center">
              <Avatar className="h-20 w-20">
                <AvatarImage src={clubDetails.logoUrl ?? '/club_logo.jpeg'} />
                <AvatarFallback>
                  {clubDetails.name.charAt(0)} {clubDetails.name.charAt(1)}
                </AvatarFallback>
              </Avatar>
            </div>
            <CardTitle className="mb-2 text-xl">{clubDetails.name}</CardTitle>
            <CardDescription>
              {clubDetails.membersSize} Mitglieder
            </CardDescription>
          </Card> */}
          <ClubCard name={name} logoUrl={logoUrl} membersSize={membersSize} />
        </Link>
      </div>
    </PageContent>
  );
}

function MembershipsSection() {
  const membershipsLength = 1;

  return (
    <PageContent className="space-y-2">
      <div className="flex items-center justify-between">
        <PageContentTitle>Deine Mitgliedsbeiträge</PageContentTitle>
        {membershipsLength > 0 && (
          <LinkButton to="/memberships" variant="link">
            Mehr sehen
          </LinkButton>
        )}
      </div>

      <MembershipsList orientation="horizontal" />
    </PageContent>
  );
}

function PurchasesSection() {
  const purchasesLength = 1;

  return (
    <PageContent className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <PageContentTitle>Abgabehistorie</PageContentTitle>
        {purchasesLength > 0 && (
          <LinkButton to="/purchases" variant="link">
            Mehr sehen
          </LinkButton>
        )}
      </div>

      <PurchasesList presentation={PurchasePresentation.SIMPLE} />
    </PageContent>
  );
}
