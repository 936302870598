import { FieldValues, Path, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputProps,
  PhoneNumberInput,
} from '../../components';
import { cn } from '../../lib/utils';

type FormPhoneNumberInputProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  placeholder?: InputProps['placeholder'];
  disabled?: boolean;
  className?: string;
  required?: boolean;
  description?: string;
};

export function FormPhoneNumberInput<T extends FieldValues>({
  name,
  label,
  placeholder,
  disabled,
  className,
  required = false,
  description,
}: FormPhoneNumberInputProps<T>) {
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn(className)}>
          {label && (
            <FormLabel>
              {label} {required && '*'}
            </FormLabel>
          )}
          <FormControl>
            <PhoneNumberInput
              className={cn({
                'border-red-600': fieldState.error,
              })}
              placeholder={placeholder}
              disabled={disabled}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              required={required}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
