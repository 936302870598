import { Button } from '@clubsoul/ui';
import { useRouter } from '@tanstack/react-router';

import { ChevronLeft } from 'lucide-react';

export function BackButton() {
  const { history } = useRouter();

  return (
    <Button onClick={() => history.back()} variant="ghost" className="p-2">
      <ChevronLeft size={24} />
    </Button>
  );
}
