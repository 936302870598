import { Outlet, createFileRoute } from '@tanstack/react-router';

import { Page } from '@/components/layout';

export const Route = createFileRoute('/_public')({
  component: Layout,
});

function Layout() {
  return (
    <Page className="h-screen min-h-screen">
      <Outlet />
    </Page>
  );
}
