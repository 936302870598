import { useLayoutEffect, useRef, useState } from 'react';

import { WaitlistIFrameEvent } from '@clubsoul/const';
import { Alert, AlertDescription, AlertTitle } from '@clubsoul/ui';
import { getApiError } from '@clubsoul/ui/utils/apiError';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { clubWaitlistQueryOptions } from '@/api/club/clubQueryOptions';
import JoinWaitlistForm from '@/form/join-waitlist-form';
import { MailCheck, TriangleAlert } from 'lucide-react';

export const Route = createFileRoute('/_public/waitlist/$clubId/join')({
  loader: ({ context: { queryClient }, params: { clubId } }) =>
    queryClient.ensureQueryData(clubWaitlistQueryOptions(clubId)),
  component: JoinWaitlistPage,
  errorComponent: ({ error }) => (
    <Alert variant="destructive">
      <TriangleAlert className="h-4 w-4" />
      <AlertTitle>Fehler aufgetreten</AlertTitle>
      <AlertDescription>
        {getApiError(error).message ?? 'Ein unbekannter Fehler ist aufgetreten'}
      </AlertDescription>
    </Alert>
  ),
});

function JoinWaitlistPage() {
  const { clubId } = Route.useParams();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: preview } = useSuspenseQuery(clubWaitlistQueryOptions(clubId));

  useLayoutEffect(() => {
    if (ref.current) {
      const payload: WaitlistIFrameEvent = {
        source: 'clubsoul-app',
        payload: { type: 'setup', height: ref.current.clientHeight },
      };

      parent.postMessage(payload, '*');
    }
  });

  return (
    <section ref={ref} className="flex items-center">
      <div className="my-4 rounded-sm border">
        <div className="p-4">
          <h1 className="mb-4 font-heading text-3xl font-bold">
            Mitgliedsanfrage
          </h1>
          <h2 className="mb-2 text-2xl font-semibold">Für: {preview.name}</h2>
          {isSuccess ? (
            <div className="mt-8 flex flex-col items-center justify-center rounded border p-4">
              <MailCheck size={46} className="mb-6 stroke-green-600" />
              <p className="text-center">
                Deine Anfrage wurde erfolgreich übermittelt, wir werden uns in
                kürze bei dir melden.
              </p>
            </div>
          ) : (
            <>
              <p className="mb-2 text-sm text-muted-foreground">
                Bitte fülle das folgende Formular aus, um dich als Mitglied in
                unserem Cannabis Social Club zu bewerben. Alle Informationen
                werden vertraulich behandelt und nur zur Bearbeitung deiner
                Anfrage verwendet.
              </p>
              <JoinWaitlistForm
                clubId={clubId}
                minimumAge={preview.minimumAge}
                onSent={() => setIsSuccess(true)}
              />
            </>
          )}
        </div>
        <a
          href="https://clubsoul.de"
          target="_blank"
          className="mt-6 flex items-center justify-center rounded-bl rounded-br bg-muted py-2 text-muted-foreground"
        >
          <span className="text-xs">Powered by ClubSoul&nbsp;&nbsp;</span>
          <img alt="ClubSoul Logo" src="/logo_black.svg" className="h-5 w-5" />
        </a>
      </div>
    </section>
  );
}
