import { Alert, AlertDescription, AlertTitle } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { NewPasswordForm } from '@/form/new-password-form';

export const Route = createFileRoute(
  '/_app/profile/security/new-password/$code',
)({
  loader: async ({ params }) => {
    console.log('resetCode', params.code);

    // TODO: Verify code
    return params.code;
  },
  component: NewPasswordPage,
});

function NewPasswordPage() {
  const { code } = Route.useParams();
  const { firstName } = {
    firstName: 'Max',
  };

  return (
    <div className="space-y-8 p-4">
      <PageHeader>
        <PageTitle>Hi {firstName}</PageTitle>
      </PageHeader>
      <PageContent>
        <Alert variant={'info'}>
          <AlertTitle>Sicherheitshinweis:</AlertTitle>
          <AlertDescription>
            Stelle sicher, dass dein neues Passwort stark und einzigartig ist,
            um den Schutz deines Kontos zu gewährleisten.
          </AlertDescription>
        </Alert>
      </PageContent>
      <PageContent>
        <PageContentTitle>Neues Passwort festlegen</PageContentTitle>

        <ul className="list-disc space-y-2 pb-4 pl-4 pt-2 text-sm">
          <li>Min. 12 Zeichen</li>
          <li>Groß- & Kleinbuchstaben</li>
          <li>Eine Zahl</li>
          <li>Ein Sonderzeichen (z.B. !, @, #) </li>
          <li>Keine Wiederholungen</li>
        </ul>

        <NewPasswordForm code={code} />
      </PageContent>
    </div>
  );
}
