import { useState } from 'react';

import {
  CreateInvitedUser,
  CreateInvitedUserFormFields,
  CreateInvitedUserFormSchema,
  createBirthDateSchema,
} from '@clubsoul/api-contracts';
import {
  Form,
  FormCalendar,
  FormCheckbox,
  FormTextInput,
  SubmitButton,
} from '@clubsoul/ui';
import { getApiError } from '@clubsoul/ui/utils/apiError';
import { useNavigate } from '@tanstack/react-router';

import InlineError from '@/auth/inline-error';
import { publicClient } from '@/lib/axios';
import { subYears } from 'date-fns';
import { z } from 'zod';

type CreateMemberAccountFormProps = Pick<
  CreateInvitedUser,
  'firstName' | 'lastName' | 'birthDate' | 'email' | 'token'
> & {
  minimumAge: number;
};

const UICreateInvitedUserFormSchema = CreateInvitedUserFormSchema.extend({
  acceptAgeRestricction: z.boolean().refine((isTrue) => isTrue, {
    message: 'Pflichtfeld',
  }),
  agbAccepted: z.boolean().refine((isTrue) => isTrue, {
    message: 'Pflichtfeld',
  }),
});

type UICreateInvitedUserForm = z.infer<typeof UICreateInvitedUserFormSchema>;

export default function CreateMemberAccountForm({
  firstName,
  lastName,
  birthDate,
  email,
  token,
  minimumAge,
}: CreateMemberAccountFormProps) {
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async ({
    agbAccepted,
    acceptAgeRestricction,
    ...fields
  }: UICreateInvitedUserForm) => {
    setIsCreating(true);
    try {
      const data = await publicClient.post('/v1/members/create-user', {
        ...fields,
        token,
      });
      navigate({
        from: '/create-member-account',
        to: '/account-verify',
        search: { token: btoa(data.email) },
        replace: true,
      });
    } catch (error) {
      setError(getApiError(error).message ?? 'Es ist einen Fehler aufgetreten');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      schema={UICreateInvitedUserFormSchema.extend({
        birthDate: createBirthDateSchema(minimumAge),
      })}
      defaultValues={{
        firstName,
        lastName,
        email,
        birthDate,
        acceptAgeRestricction: false,
        agbAccepted: false,
      }}
    >
      <FormTextInput<CreateInvitedUserFormFields>
        name="username"
        label="Benutzername"
        required
      />
      <FormTextInput<CreateInvitedUserFormFields>
        name="firstName"
        label="Vorname"
        required
      />
      <FormTextInput<CreateInvitedUserFormFields>
        name="lastName"
        label="Nachname"
        required
      />
      <FormTextInput<CreateInvitedUserFormFields>
        name="email"
        label="E-Mail"
        required
        disabled
      />
      <FormCalendar<CreateInvitedUserFormFields>
        name="birthDate"
        label="Geburtsdatum"
        description={`Dieser Club erlaubt nur Mitglieder, die über ${minimumAge} Jahre alt sind`}
        required
        initialFocus
        captionLayout="dropdown"
        fromYear={1900}
        toYear={subYears(new Date(), minimumAge).getFullYear()}
      />
      <FormTextInput<CreateInvitedUserFormFields>
        name="password"
        label="Passwort"
        type="password"
        description="Das Passwort muss aus mindestens 8 Zeichen bestehen und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen (@$!%*?&#/\) enthalten."
        required
      />
      <FormCheckbox<UICreateInvitedUserForm>
        name="acceptAgeRestricction"
        label={`Ich bestätige dass alle meine Angaben Korrekt sind und mindestens ${minimumAge} Jahre alt bin.`}
        required
      />
      <FormCheckbox<UICreateInvitedUserForm>
        name="agbAccepted"
        required
        label={
          <span>
            Ich habe die{' '}
            <a
              href={import.meta.env.VITE_AGB_URL}
              className="underline"
              target="_blank"
            >
              Nutzungsbedingungen
            </a>
            <span> und die </span>
            <a
              href={import.meta.env.VITE_PRIVACY_POLICY_URL}
              className="underline"
              target="_blank"
            >
              Datenschutzrichtlinien
            </a>{' '}
            gelesen und akzeptiere die.
          </span>
        }
      />
      <div>
        <SubmitButton isLoading={isCreating} className="mt-4 w-full">
          Konto erstellen
        </SubmitButton>
      </div>
      {error && <InlineError className="mt-2">{error}</InlineError>}
    </Form>
  );
}
