'use client';

import { useEffect, useState } from 'react';

import {
  ResendVerificationRequest,
  ResendVerificationRequestSchema,
} from '@clubsoul/api-contracts';
import { Button, Form } from '@clubsoul/ui';
import * as Sentry from '@sentry/react';

import { publicClient } from '@/lib/axios';

const TIMER = 59;

export type ResendButtonProps = {
  email: string;
};

export function ResendVerificationForm(props: ResendButtonProps) {
  const { email } = props;

  const { label: timeLabel, isRunning, resetTimer } = useCountdownTimer(TIMER);

  const onSubmit = async (data: ResendVerificationRequest) => {
    try {
      const body: ResendVerificationRequest = { ...data, email };
      await publicClient.post('/v1/auth/verification', body);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      resetTimer();
    }
  };

  return (
    <Form
      className="w-full"
      schema={ResendVerificationRequestSchema}
      onSubmit={onSubmit}
      defaultValues={{
        email,
        origin: 'member',
      }}
    >
      <Button className="w-full" disabled={isRunning}>
        {isRunning ? timeLabel : 'Verifizierungs-E-Mail erneut senden'}
      </Button>
    </Form>
  );
}

/**
 * Convert seconds to "mm:ss" format
 */
function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function useCountdownTimer(start: number) {
  const [seconds, setSeconds] = useState<number>(start);

  useEffect(() => {
    const handler = () => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          }
          clearInterval(intervalId);
          return 0;
        });
      }
    };
    const intervalId = setInterval(handler, 1000);
    return () => clearInterval(intervalId);
  }, [seconds]);

  const resetTimer = () => setSeconds(start);

  return {
    time: seconds,
    label: formatTime(seconds),
    isRunning: seconds !== 0,
    resetTimer,
  };
}
