import { ReactNode } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Separator,
} from '@clubsoul/ui';
import { Link } from '@tanstack/react-router';

import {
  Loader2,
  LucideIcon,
  MailCheck,
  MailSearch,
  MailXIcon,
} from 'lucide-react';

export function VerifiedCard() {
  return (
    <CardLayout icon={MailCheck} title={'E-Mail verifiziert'}>
      <CardDescription className="mb-4 font-semibold">
        Deine E-Mail Adresse wurde erfolgreich verifiziert.
      </CardDescription>

      <CardDescription className="mb-6">
        Dein Konto ist jetzt aktiv. Bitte benutze den Link, um dich in deinem
        Konto einzuloggen.
      </CardDescription>

      <Link to="/login">
        <Button className="w-full">Einloggen</Button>
      </Link>
    </CardLayout>
  );
}

export function VerifyingCard() {
  return (
    <CardLayout icon={MailSearch}>
      <div className="flex justify-center">
        <Loader2 className="animate-spin" />
      </div>
    </CardLayout>
  );
}

export function ErrorVerifyingCard() {
  return (
    <CardLayout icon={MailXIcon} title={'Fehler bei der Verifizierung'}>
      <CardDescription>
        Bei der verifizierung ist irgendwas schief gelaufen. Wenn du mehr Hilfe
        brauchst, kontaktiere unseren Support.
      </CardDescription>
    </CardLayout>
  );
}

type CardLayoutProps = {
  icon: LucideIcon;
  title?: ReactNode;
  children: ReactNode;
};

function CardLayout(props: CardLayoutProps) {
  const { icon: Icon, title, children } = props;

  return (
    <Card className="w-full md:max-w-[494px]">
      <CardHeader className="space-y-6">
        <Icon size={48} className="self-center" />
        <Separator />
        {title && <CardTitle>{title}</CardTitle>}
      </CardHeader>

      <CardContent>{children}</CardContent>
    </Card>
  );
}
