import { Alert, AlertDescription, AlertTitle } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { LinkButton } from '@/components/link-button';

export const Route = createFileRoute('/_app/profile/delete/')({
  component: ProfileDeletePage,
});

function ProfileDeletePage() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="grow text-center">Account löschen</PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <Alert variant={'info'}>
          <AlertTitle>
            Bist du sicher, dass du deinen Account löschen möchtest?
          </AlertTitle>
          <AlertDescription>
            Nach Bestätigung des Buttons erhältst du eine Bestätigungsmail an
            deine registrierte E-Mail Adresse.
          </AlertDescription>
          <AlertDescription className="pt-4">
            Folge den Anweisungen in der E-Mail, um die Löschung abzuschließen.
          </AlertDescription>
        </Alert>
      </PageContent>

      <PageContent>
        <LinkButton
          className="w-full"
          variant={'destructive'}
          to="/profile/delete/confirm"
        >
          Löschung beantragen
        </LinkButton>
      </PageContent>
    </div>
  );
}
