import { Alert, AlertDescription, AlertTitle } from '@clubsoul/ui';
import { cn } from '@clubsoul/ui/lib/utils';

import { AlertCircle } from 'lucide-react';

type FullscreenErrorProps = {
  title?: string;
  message?: string;
  className?: string;
};

export default function FullscreenError({
  title = 'Einen Fehler ist aufgetreten',
  message = 'Wir könnten die Anfrage nicht bearbeiten.',
  className,
}: FullscreenErrorProps) {
  return (
    <div className={cn('flex justify-center p-4', className)}>
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </div>
  );
}
