import { LucideIcon } from 'lucide-react';

export type StepProps = {
  icon: LucideIcon;
  title: string;
  description: string;
};

export function Step(props: StepProps) {
  const { icon: Icon, title, description } = props;

  return (
    <div className="flex flex-nowrap gap-2">
      <Icon size={24} className="min-w-6 stroke-emerald-400" />
      <div className="space-y-2">
        <h3 className="font-semibold text-emerald-400">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
