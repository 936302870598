import { MemberPurchase } from '@clubsoul/api-contracts';
import { Avatar, AvatarFallback, AvatarImage } from '@clubsoul/ui';

import { Eye, Star } from 'lucide-react';

export const enum PurchasePresentation {
  SIMPLE = 'simple',
  DETAILED = 'detailed',
}

export type PurchaseItemProps = MemberPurchase & {
  presentation: PurchasePresentation;
};

export function PurchaseItem(props: PurchaseItemProps) {
  const { name, date, quantity, membershipName, presentation } = props;

  if (presentation === PurchasePresentation.SIMPLE) {
    return (
      <div className="flex flex-row items-center gap-4 py-2">
        <div>
          <Avatar className="h-16 w-16">
            <AvatarImage src={''} />
            <AvatarFallback>HB</AvatarFallback>
          </Avatar>
        </div>

        <div className="grow">
          <h5 className="font-medium">{name}</h5>
          <span className="font-light text-gray-400">{date}</span>
        </div>

        <div className="text-end font-medium">
          <p className="text-green-500">{quantity}g</p>
          <span className="">{membershipName}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 divide-y">
      <div className="flex flex-row items-center gap-4">
        <Avatar className="h-28 w-28">
          <AvatarImage src={''} />
          <AvatarFallback>HB</AvatarFallback>
        </Avatar>

        <div className="flex grow flex-col justify-between gap-2 self-stretch">
          <div>
            <h5 className="font-medium">{name}</h5>
            <span className="font-light text-gray-400">{date}</span>
          </div>

          <div className="font-light text-gray-400">
            <p>Cannabis</p>
            <p>Indica</p>
          </div>
        </div>

        <div className="flex flex-col items-end justify-between self-stretch">
          <div>
            <Eye />
          </div>

          <div className="text-end font-medium">
            <span className="">{membershipName}</span>
            <p className="text-green-500">{quantity}g</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-4 pt-4 text-gray-500">
        <span>Rating</span>
        <span className="inline-flex gap-1">
          <Star color="green" />
          <Star color="green" />
          <Star color="green" />
          <Star />
          <Star />
        </span>

        <span>3/5 (50)</span>
      </div>
    </div>
  );
}
