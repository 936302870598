import { useState } from 'react';

import { Trash2 } from 'lucide-react';
import { z } from 'zod';
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  NestedCredenzaTrigger,
  NestedCredenzaTriggerProps,
} from './credenza';
import { FileUploaderProps } from './file-uploader';
import { Form, FormFileUploader } from './form';
import { Avatar, AvatarImage } from './ui/avatar';
import { Button, SubmitButton } from './ui/button';

export type UploadAvatarDialogProps = Pick<
  NestedCredenzaTriggerProps,
  'renderTrigger'
> &
  Omit<FileUploaderProps, 'onUpload'> & {
    onUpload: (file: File) => Promise<void>;
  };

type Data = {
  files: File[];
};

export function UploadAvatarDialog(props: UploadAvatarDialogProps) {
  const { onUpload, renderTrigger, ...otherProps } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async (data: Data) => {
    setIsUpdating(true);
    try {
      await onUpload(data.files[0]!);
      setIsOpen(false);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Credenza open={isOpen} onOpenChange={setIsOpen}>
      <NestedCredenzaTrigger asChild renderTrigger={renderTrigger} />
      <CredenzaContent>
        <CredenzaHeader className="pb-4 sm:text-center">
          <CredenzaTitle>Bearbeiten Profilbild</CredenzaTitle>
        </CredenzaHeader>

        <Form<Data>
          onSubmit={handleSubmit}
          schema={z.object({ files: z.instanceof(File).array() })}
        >
          <CredenzaBody className="space-y-4">
            <FormFileUploader<Data>
              name="files"
              multiple={false}
              hideUploadOnMaxFiles
              parentClassName="flex-col-reverse"
              renderFiles={(files, removeFile) => {
                if (files.length === 0) {
                  return null;
                }

                return (
                  <div className="flex justify-center">
                    <div className="group relative h-52 w-52 overflow-hidden rounded-full has-[button:focus]:opacity-60 has-[button:hover]:opacity-60">
                      <Avatar className="h-full w-full">
                        <AvatarImage
                          className="object-cover"
                          src={URL.createObjectURL(files[0]!)}
                        />
                      </Avatar>

                      <div className="absolute inset-0 hidden items-end justify-center group-hover:flex">
                        <Button
                          type="button"
                          className="hover:fade h-10 w-full bg-slate-900 p-2"
                          onClick={() => removeFile(0)}
                        >
                          <Trash2 size={30} color="white" strokeWidth={1.5} />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              }}
              {...otherProps}
            />
          </CredenzaBody>
          <CredenzaFooter className="pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => setIsOpen(false)}
            >
              Abbrechen
            </Button>
            <SubmitButton isLoading={isUpdating}>Foto hochladen</SubmitButton>
          </CredenzaFooter>
        </Form>
      </CredenzaContent>
    </Credenza>
  );
}
