import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  CardDescription,
  CardTitle,
} from '@clubsoul/ui';

type ClubCardProps = {
  name: string;
  logoUrl?: string;
  membersSize: number;
};

export default function ClubCard({
  name,
  logoUrl,
  membersSize,
}: ClubCardProps) {
  return (
    <Card>
      <div className="overflow-hidden rounded-t-lg">
        <img
          className="h-32 w-full object-cover"
          src="/club_banner.webp"
          alt="Banner"
        />
      </div>

      <div className="relative p-4 pt-16 text-center">
        <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 transform">
          <Avatar className="h-24 w-24 border-2">
            <AvatarImage src={logoUrl ?? '/club_logo.jpeg'} />
            <AvatarFallback>
              {name.charAt(0)} {name.charAt(1)}
            </AvatarFallback>
          </Avatar>
        </div>
        <CardTitle className="font-heading text-2xl">{name}</CardTitle>
        <CardDescription>{membersSize} Mitglieder</CardDescription>
      </div>
    </Card>
  );
}
