import { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form-base';
import { Input, InputProps } from '../../components/ui/input';

import { cn } from '../../lib/utils';

import { FieldTransform } from '../../types/input.types';

import { FLOAT_REGEX } from '../../utils/validation';

import { defaultTextTransform } from './helpers';

type FormNumberInputProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  placeholder?: InputProps['placeholder'];
  type?: InputProps['type'];
  transform?: Required<FieldTransform>;
  disabled?: boolean;
  description?: string;
  rightSlot?: ReactNode;
  className?: string;
  required?: boolean;
  inputClassName?: string;
};

export function FormNumberInput<T extends FieldValues = FieldValues>({
  name,
  label,
  placeholder,
  transform = defaultTextTransform,
  disabled,
  description,
  rightSlot,
  className,
  required = false,
  inputClassName,
}: FormNumberInputProps<T>) {
  const { control } = useFormContext();
  const hasRightSlot = !!rightSlot;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('my-4 w-fit', className)}>
          <FormLabel>
            {label} {required && '*'}
          </FormLabel>
          <FormControl>
            <div className="flex max-w-[max-content] items-center justify-between rounded-md border border-input bg-background text-sm">
              <Input
                ref={field.ref}
                className={cn(
                  'relative max-w-[100px] border-none',
                  {
                    'border-red-600': fieldState.error,
                    'rounded-br-none rounded-tr-none': hasRightSlot,
                  },
                  inputClassName,
                )}
                placeholder={placeholder}
                type="number"
                autoComplete="off"
                value={transform.input(field.value)}
                onChange={(e) => {
                  if (
                    e.target.value !== '' &&
                    !e.target.value.endsWith('.') &&
                    !FLOAT_REGEX.exec(e.target.value)
                  ) {
                    return;
                  }

                  field.onChange(transform.output(e.target.value));
                }}
                onBlur={field.onBlur}
                disabled={disabled}
                required={required}
              />
              {rightSlot && (
                <div className="h-10 border-l bg-gray-50 px-3 py-2">
                  {rightSlot}
                </div>
              )}
            </div>
          </FormControl>
          {description && (
            <FormDescription className="text-xs">{description}</FormDescription>
          )}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
