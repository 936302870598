import { MemberPurchase } from '@clubsoul/api-contracts';

export function usePurchases(): MemberPurchase[] {
  return [
    {
      id: '1',
      imageUrl: undefined,
      name: 'Hogs-Breath',
      date: '2024-08-12',
      quantity: 20,
      membershipName: 'Gold',
    },
    {
      id: '2',
      imageUrl: undefined,
      name: 'Viper',
      date: '2024-09-12',
      quantity: 5,
      membershipName: 'Silber',
    },
    {
      id: '3',
      imageUrl: undefined,
      name: 'Black-Afghan',
      date: '2024-10-12',
      quantity: 5,
      membershipName: 'Bronze',
    },
    {
      id: '4',
      imageUrl: undefined,
      name: 'Big-Sky-Oq',
      date: '2024-1-12',
      quantity: 12,
      membershipName: 'Gold',
    },
  ];
}
