import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import AuthLayout from '@/auth/auth-layout';
import ResetPasswordForm from '@/auth/reset-password-form';
import FullscreenError from '@/components/fullscreen-error';
import { Page, PageContent } from '@/components/layout';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/reset-password')({
  component: ResetPasswordPage,
  validateSearch: z.object({
    oobCode: z.string(),
  }),
  errorComponent: () => (
    <AuthLayout>
      <FullscreenError />
    </AuthLayout>
  ),
});

function ResetPasswordPage() {
  const { oobCode } = Route.useSearch();
  return (
    <AuthLayout>
      <Page>
        <PageContent className="mt-8 rounded-t-md bg-white px-4">
          <Card>
            <CardHeader>
              <CardTitle>Passwort zurücksetzen</CardTitle>
              <CardDescription>Gib dein neues Passwort ein</CardDescription>
            </CardHeader>

            <CardContent>
              <ResetPasswordForm oobCode={oobCode} />
            </CardContent>
          </Card>
        </PageContent>
      </Page>
    </AuthLayout>
  );
}
