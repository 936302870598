import { toast } from 'sonner';
import { useSessionStorage } from '../hooks/use-session-storage';
import { Button } from './ui/button';

export type VerifyEmailBannerProps = {
  resendEmail: () => Promise<void>;
};

export function VerifyEmailBanner(props: VerifyEmailBannerProps) {
  const { resendEmail } = props;
  const [isEmailResent, setIsEmailResent] = useSessionStorage(
    'resent-email-verification',
    false,
    false,
  );

  const handleClick = async () => {
    setIsEmailResent(true);
    try {
      await resendEmail();
      toast.success('Neue Verifizierungsemail wurde erfolgreich gesendet');
    } catch (e) {
      console.log(e, 'E-Mail kann nicht gesendet werden');
    }
  };

  return (
    <div className="flex flex-col justify-between gap-2 bg-yellow-200/65 px-4 py-2 sm:flex-row sm:items-center">
      <p className="text-sm text-yellow-800">
        Dein Konto ist noch nicht verifiziert. Bitte überprüfe deine E-Mail und
        verifiziere dein Konto.
      </p>

      <Button
        variant={'link'}
        className="min-w-fit self-baseline pl-0 sm:self-auto sm:pl-4"
        disabled={isEmailResent}
        onClick={handleClick}
      >
        {isEmailResent ? 'E-Mail gesendet' : 'E-Mail erneut senden'}
      </Button>
    </div>
  );
}
