'use client';

import { useEffect, useState } from 'react';

import {
  ErrorVerifyingCard,
  VerifiedCard,
  VerifyingCard,
} from '@/auth/verification-cards';
import { auth } from '@/lib/firebase';
import { applyActionCode } from 'firebase/auth';

type Status = 'VERIFYING' | 'VERIFIED' | 'ERROR';

const card: Record<Status, () => JSX.Element> = {
  VERIFYING: VerifyingCard,
  VERIFIED: VerifiedCard,
  ERROR: ErrorVerifyingCard,
};

type AccountVerifiedCardProps = {
  oobCode: string;
};

export default function AccountVerifiedCard(props: AccountVerifiedCardProps) {
  const { oobCode } = props;
  const { status } = useVerifyCode(oobCode);
  const Card = card[status];

  return <Card />;
}

function useVerifyCode(oobCode: string) {
  const [status, setStatus] = useState<Status>('VERIFYING');

  const verify = async () => {
    try {
      await applyActionCode(auth, oobCode);

      setStatus('VERIFIED');
    } catch (error) {
      setStatus('ERROR');
    }
  };

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { status };
}
