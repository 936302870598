import { Navigate, createFileRoute } from '@tanstack/react-router';

import AuthLayout from '@/auth/auth-layout';
import { VerifyCard } from '@/auth/verify-card';
import FullscreenError from '@/components/fullscreen-error';
import { Page, PageContent } from '@/components/layout';
import { ZodError, z } from 'zod';

export const Route = createFileRoute('/_auth/account-verify')({
  component: AccountVerify,
  errorComponent: ({ error }) => {
    const message = error instanceof ZodError ? 'Ungültiger token' : undefined;

    return (
      <AuthLayout>
        <FullscreenError message={message} />
      </AuthLayout>
    );
  },
  validateSearch: z.object({
    token: z.string(),
  }),
});

function AccountVerify() {
  const { token } = Route.useSearch();
  const decodedEmail = atob(token);

  const emailResult = z.string().email().safeParse(decodedEmail);

  if (!emailResult.success) {
    return <Navigate from="/account-verify" to="/login" replace />;
  }

  const email = emailResult.data;

  return (
    <AuthLayout>
      <Page>
        <PageContent className="mt-8 flex justify-center px-4">
          <VerifyCard email={email} />
        </PageContent>
      </Page>
    </AuthLayout>
  );
}
