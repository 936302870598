import { useCallback, useState } from 'react';

export function useRerender(): [number, () => void] {
  const [signal, setSignal] = useState(0);

  const rerender = useCallback(() => {
    setSignal((old) => old + 1);
  }, []);

  return [signal, rerender];
}
