'use client';

import { ResendVerificationRequest } from '@clubsoul/api-contracts';
import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/auth/useAuth';
import useUser from '@/auth/useUser';
import { publicClient } from '@/lib/axios';

export function useResendUserVerification() {
  const { email } = useUser();
  const { refetchSession } = useAuth();

  return useMutation({
    mutationFn: () => {
      const body: ResendVerificationRequest = {
        email,
        origin: 'member',
      };
      return publicClient.post<void>(`/v1/auth/verification`, body);
    },
    onSuccess: () => refetchSession(),
  });
}
