import { Pageable } from '@clubsoul/api-contracts';
import { defaultPagination } from '@clubsoul/const';
import { objectPropsToString } from '@clubsoul/utils';

import { Axios } from 'axios';

export class BaseClient {
  constructor(protected readonly client: Axios) {}

  async get<T = any>(url: string) {
    const res = await this.client.get<T>(url);
    return res.data;
  }

  async getPaginated(url: string, pageable: Pageable = defaultPagination) {
    const params = new URLSearchParams(objectPropsToString(pageable));
    const res = await this.client.get(`${url}?${params.toString()}`);
    return res.data;
  }

  async postPaginated(
    url: string,
    pageable: Pageable = defaultPagination,
    body: unknown,
  ) {
    const params = new URLSearchParams(objectPropsToString(pageable));
    const res = await this.client.post(`${url}?${params.toString()}`, body);
    return res.data;
  }

  async post<T = any>(url: string, body?: unknown) {
    const res = await this.client.post<T>(url, body);
    return res.data;
  }

  async put<T = any>(url: string, body?: unknown) {
    const res = await this.client.put<T>(url, body);
    return res.data;
  }

  async patch<T = any>(url: string, body?: unknown) {
    const res = await this.client.patch<T>(url, body);
    return res.data;
  }

  async delete<T = any>(url: string) {
    const res = await this.client.delete<T>(url);
    return res.data;
  }
}
