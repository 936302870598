import { useState } from 'react';

import {
  SendResetPasswordRequest,
  SendResetPasswordRequestSchema,
} from '@clubsoul/api-contracts';
import { Form, FormTextInput, SubmitButton } from '@clubsoul/ui';
import { useNavigate } from '@tanstack/react-router';

import InlineError from '@/auth/inline-error';
import { auth } from '@/lib/firebase';
import { confirmPasswordReset } from 'firebase/auth';

type ComponentState = 'NEUTRAL' | 'LOADING' | 'ERROR' | 'SUCCESS';

type ResetPasswordFormProps = {
  oobCode: string;
};

export default function ResetPasswordForm({ oobCode }: ResetPasswordFormProps) {
  const navigate = useNavigate({ from: '/reset-password' });
  const [status, setStatus] = useState<ComponentState>('NEUTRAL');
  const [error] = useState<string | undefined>(
    'Es ist ein Fehler aufgetreten. Bitte fordere eine neue E-Mail zum Zurücksetzen des Passworts an oder kontaktiere unseren Support.',
  );

  const handleSubmit = async (data: SendResetPasswordRequest) => {
    setStatus('LOADING');
    try {
      await confirmPasswordReset(auth, oobCode, data.newPassword);
      navigate({ to: '/login' });
    } catch (e) {
      setStatus('ERROR');
    }
  };

  return (
    <Form
      schema={SendResetPasswordRequestSchema}
      onSubmit={handleSubmit}
      defaultValues={{ newPassword: '', confirmPassword: '', oobCode }}
    >
      <FormTextInput
        label="Neues Passwort"
        type="password"
        name="newPassword"
        placeholder="**********"
      />
      <FormTextInput
        label="Neues Passwort wiederholen"
        type="password"
        name="confirmPassword"
        placeholder="**********"
      />
      <SubmitButton isLoading={status === 'LOADING'} className="w-full">
        Zurücksetzen
      </SubmitButton>
      {status === 'ERROR' && <InlineError>{error}</InlineError>}
    </Form>
  );
}
