import { ReactNode, useId } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { cn } from '../../lib/utils';
import { FileUploader, FileUploaderProps } from '../file-uploader';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form-base';

type FormFileUploaderProps<T extends FieldValues> = FileUploaderProps & {
  name: Path<T>;
  label?: ReactNode;
  className?: string;
  required?: boolean;
};

export function FormFileUploader<T extends FieldValues>({
  name,
  label,
  disabled,
  className,
  required = false,
  ...otherProps
}: FormFileUploaderProps<T>) {
  const id = useId();
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn(className)}>
          {label && (
            <FormLabel htmlFor={id} className="">
              {label} {required && '*'}
            </FormLabel>
          )}

          <FormControl>
            <FileUploader
              value={field.value}
              onValueChange={field.onChange}
              disabled={disabled}
              {...otherProps}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
