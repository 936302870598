import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Separator,
} from '@clubsoul/ui';

import { Mail } from 'lucide-react';

import { ResendVerificationForm } from './resend-verification-form';

export type VerificationCardProps = {
  email: string;
};

export function VerifyCard(props: VerificationCardProps) {
  const { email } = props;

  return (
    <Card className="w-full md:max-w-[494px]">
      <CardHeader className="items-center space-y-6">
        <Mail size={48} />
        <Separator />
      </CardHeader>

      <CardContent className="space-y-4">
        <CardTitle>Bitte verifiziere deine E-Mail-Adresse</CardTitle>
        <CardDescription>
          Klick einfach auf den Link in dieser E-Mail, um deine Registrierung
          abzuschließen. Wenn du ihn nicht siehst, musst du möglicherweise
          deinen <b>Spam-Ordner überprüfen.</b>
        </CardDescription>
      </CardContent>
      <CardContent>
        <CardDescription>
          Du kannst die E-Mail immer noch nicht finden? Kein Problem!
        </CardDescription>
      </CardContent>

      <CardFooter className="flex-col space-y-4">
        <ResendVerificationForm email={email} />
        <CardDescription>
          Du kannst die Verifizierungs-E-Mail nach Ablauf der verbleibenden Zeit
          erneut senden
        </CardDescription>
      </CardFooter>
    </Card>
  );
}
