import { createFileRoute } from '@tanstack/react-router';

import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { LinkButton } from '@/components/link-button';
import { Step } from '@/components/step';
import { CheckCircle, Eye, Weight } from 'lucide-react';

export const Route = createFileRoute('/_app/check-in/')({
  component: CheckInPage,
});

function CheckInPage() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8 text-center">
        <PageTitle>Check-In</PageTitle>

        <p className="text-xl font-semibold">
          Willkommen bei deiner Abgabestelle! Bitte folge diesen Schritten für
          einen reibungslosen Check-in:
        </p>
      </PageHeader>

      <PageContent className="space-y-8 p-4">
        <Step
          icon={Eye}
          title="1. QR-Code zeigen"
          description="Bitte zeige deinen QR-Code an der Abgabestelle vor."
        />

        <Step
          icon={CheckCircle}
          title="2. Identifikation"
          description="Halte deinen Personalausweis bereit. Unsere Mitarbeiter prüfen deine Identität."
        />

        <Step
          icon={Weight}
          title="3. Auswahl treffen:"
          description="Geschafft! Jetzt nehmen unsere Mitarbeiter deine Bestellung entgegen."
        />
      </PageContent>

      <PageContent className="text-center">
        <LinkButton to="/check-in/qr-code">Zum QR-Code</LinkButton>
      </PageContent>
    </div>
  );
}
