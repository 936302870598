import {
  Club,
  ClubSocialInfo,
  ClubWaitlistPreview,
  MemberClubDetails,
} from '@clubsoul/api-contracts';
import { queryOptions } from '@tanstack/react-query';

import { clubKeys } from '@/api/club/club.keys';
import { authClient } from '@/lib/axios';

export const clubDetailsQueryOptions = () =>
  queryOptions({
    queryKey: clubKeys.getDetails(),
    queryFn: () => authClient.get<MemberClubDetails>('/v1/clubs/app/details'),
  });

export const clubSocialInfoQueryOptions = () =>
  queryOptions({
    queryKey: clubKeys.getSocialInfo(),
    queryFn: () => authClient.get<ClubSocialInfo>('/v1/clubs/social-info'),
  });

export const clubWaitlistQueryOptions = (id: Club['id']) =>
  queryOptions({
    queryKey: clubKeys.getWailistPreview(id),
    queryFn: () =>
      authClient.get<ClubWaitlistPreview>(`/v1/clubs/${id}/waitlist-preview`),
  });
