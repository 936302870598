import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

import { userDetailsQueryOptions } from '@/api/user/userQueryOptions';
import { BackButton } from '@/components/back-button';
import { EditProfileDetailsModal } from '@/components/edit-profile-details-modal';
import {
  PageContent,
  PageFooter,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { ProfileAvatar } from '@/components/profile-avatar';

export const Route = createFileRoute('/_app/profile/details')({
  component: ProfileDetailsPage,
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(userDetailsQueryOptions()),
});

function ProfileDetailsPage() {
  const { data } = useSuspenseQuery(userDetailsQueryOptions());

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Persönliche Daten
          </PageTitle>
        </div>

        <div className="flex justify-center">
          <div className="h-32 w-32">
            <ProfileAvatar url={data.profilePic} />
          </div>
        </div>
      </PageHeader>

      <PageContent className="max-w-[500px] space-y-4">
        <Field name="Benutzername" value={data.username} />
        <div className="flex flex-col gap-4 sm:flex-row">
          <Field name="Straße + Nr." value={data.address.street} />
          <Field name="PLZ" value={data.address.postalCode} />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <Field name="Stadt" value={data.address.city} />
          <Field name="Land" value={data.address.co} />
        </div>
        <Field name="E-Mail" value={data.email} />
        <Field name="Geburtsdatum" value={data.birthDate} />
      </PageContent>

      <PageFooter className="flex justify-center">
        <EditProfileDetailsModal data={data} />
      </PageFooter>
    </div>
  );
}

type FieldProps = {
  name: string;
  value?: string;
};

const defaultValue = '-';

function Field(props: FieldProps) {
  const { name, value } = props;

  return (
    <div className="grow basis-1/2 space-y-2 text-sm">
      <p className="font-semibold">{name}</p>
      <p>{value ?? defaultValue}</p>
    </div>
  );
}
