import { DayPickerSingleProps } from 'react-day-picker';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import { UTCDate } from '@date-fns/utc';

import { format, formatISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';

import { Button } from '../../components/ui/button';
import { Calendar } from '../../components/ui/calendar';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form-base';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';

import { cn } from '../../lib/utils';

import { FieldTransform } from '../../types/input.types';

type CalendarInputTransform = Required<
  Pick<
    FieldTransform<string | undefined | Date, Date | undefined>,
    'input' | 'output'
  >
>;

const defaultStringToDateTransform: CalendarInputTransform = {
  input: (v) => {
    return v ? new UTCDate(v) : undefined;
  },
  output: (v: Date | undefined) => {
    return v ? formatISO(v, { representation: 'date' }) : undefined;
  },
};

export type FormCalendarProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  description?: string;
  transform?: CalendarInputTransform;
} & Omit<DayPickerSingleProps, 'mode'>;

export function FormCalendar<T extends FieldValues>({
  name,
  description,
  label,
  transform = defaultStringToDateTransform,
  ...calendarProps
}: FormCalendarProps<T>) {
  const { control } = useFormContext<T>();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {label} {calendarProps.required && '*'}
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  ref={field.ref}
                  variant={'input'}
                  className={cn(
                    'w-full pl-3 text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  {field.value ? (
                    format(transform.input(field.value) as Date, 'PPP', {
                      locale: de,
                    })
                  ) : (
                    <span>Datum auswählen</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                selected={transform.input(field.value)}
                onSelect={(d) => field.onChange(transform.output(d))}
                defaultMonth={transform.input(field.value)}
                locale={de}
                {...calendarProps}
                mode="single"
              />
            </PopoverContent>
          </Popover>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
