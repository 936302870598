import { MemberSession } from '@clubsoul/api-contracts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { globalKeys } from '@/api/club/global.keys';
import { authClient } from '@/lib/axios';

export const getSessionQueryOptions = queryOptions({
  queryKey: globalKeys.session,
  queryFn: () => authClient.get<MemberSession>('/v1/auth/session'),
});

export const useGetSession = () => useQuery(getSessionQueryOptions);
