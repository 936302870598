import { countries } from '@clubsoul/utils';
import parsePhoneNumberFromString, {
  AsYouType,
  type CountryCode,
} from 'libphonenumber-js';
import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
} from '../components';
import { cn } from '../lib/utils';

interface PhoneInputProps extends React.ComponentPropsWithoutRef<'input'> {
  value?: string;
  defaultCountry?: CountryCode;
}

export function PhoneNumberInput({
  value: valueProp,
  defaultCountry = 'DE',
  className,
  id,
  required = true,
  ...rest
}: PhoneInputProps) {
  const asYouType = new AsYouType();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, handlers, history] = useStateHistory(valueProp);

  if (value && value.length > 0) {
    defaultCountry =
      parsePhoneNumberFromString(value)?.getPossibleCountries()[0] ||
      defaultCountry;
  }

  const [openCommand, setOpenCommand] = React.useState(false);
  const [countryCode, setCountryCode] =
    React.useState<CountryCode>(defaultCountry);

  const selectedCountry = countries.find(
    (country) => country.iso2 === countryCode,
  );

  const initializeDefaultValue = () => {
    if (value) {
      return value;
    }

    return `+${selectedCountry?.phone_code}`;
  };

  const handleOnInput = (event: React.FormEvent<HTMLInputElement>) => {
    asYouType.reset();

    let value = event.currentTarget.value;
    if (!value.startsWith('+')) {
      value = `+${value}`;
    }

    const formattedValue = asYouType.input(value);
    const number = asYouType.getNumber();
    setCountryCode(number?.country || defaultCountry);
    event.currentTarget.value = formattedValue;
    handlers.set(formattedValue);
  };

  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    asYouType.reset();

    const clipboardData = event.clipboardData;

    if (clipboardData) {
      const pastedData = clipboardData.getData('text/plain');
      const formattedValue = asYouType.input(pastedData);
      const number = asYouType.getNumber();
      setCountryCode(number?.country || defaultCountry);
      event.currentTarget.value = formattedValue;
      handlers.set(formattedValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
      handlers.back();
      if (
        inputRef.current &&
        history.current > 0 &&
        history.history[history.current - 1] !== undefined
      ) {
        event.preventDefault();
        inputRef.current.value = history.history[history.current - 1] || '';
      }
    }
  };

  return (
    <div className={cn('flex gap-2', className)}>
      <Popover open={openCommand} onOpenChange={setOpenCommand} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={openCommand}
            className="w-max items-center justify-between whitespace-nowrap border-border"
          >
            {selectedCountry?.name ? (
              <span className="relative top-0.5">{selectedCountry.emoji}</span>
            ) : (
              'Land auswählen'
            )}
            <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-max p-0" align="start">
          <Command>
            <CommandInput placeholder="Land suchen..." />
            <CommandList>
              <CommandEmpty>Kein Land gefunden.</CommandEmpty>
              <ScrollArea
                className={
                  '[&>[data-radix-scroll-area-viewport]]:max-h-[300px]'
                }
              >
                <CommandGroup>
                  {countries.map((country) => {
                    return (
                      <CommandItem
                        key={country.iso3}
                        value={`${country.name} (+${country.phone_code})`}
                        onSelect={() => {
                          if (inputRef.current) {
                            inputRef.current.value = `+${country.phone_code}`;
                            handlers.set(`+${country.phone_code}`);
                            inputRef.current.focus();
                          }
                          setCountryCode(country.iso2 as CountryCode);
                          setOpenCommand(false);
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 size-4',
                            countryCode === country.iso2
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                        <span className="relative mr-2 h-4 w-4 object-cover">
                          {country.emoji}
                        </span>
                        {country.translations.de ?? country.name}
                        <span className="text-gray-11 ml-1">
                          (+{country.phone_code})
                        </span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </ScrollArea>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Input
        ref={inputRef}
        type="text"
        pattern="^(\+)?[0-9\s]*$"
        name="phone"
        id={id}
        placeholder="Telefonnummer"
        defaultValue={initializeDefaultValue()}
        onInput={handleOnInput}
        onPaste={handleOnPaste}
        onKeyDown={handleKeyDown}
        required={required}
        aria-required={required}
        {...rest}
      />
    </div>
  );
}

interface UseStateHistoryHandlers<T> {
  set: (value: T) => void;
  back: (steps?: number) => void;
  forward: (steps?: number) => void;
}

interface StateHistory<T> {
  history: T[];
  current: number;
}

function useStateHistory<T>(
  initialValue: T,
): [T, UseStateHistoryHandlers<T>, StateHistory<T>] {
  const [state, setState] = React.useState<StateHistory<T>>({
    history: [initialValue],
    current: 0,
  });

  const set = React.useCallback(
    (val: T) =>
      setState((currentState) => {
        const nextState = [
          ...currentState.history.slice(0, currentState.current + 1),
          val,
        ];
        return {
          history: nextState,
          current: nextState.length - 1,
        };
      }),
    [],
  );

  const back = React.useCallback(
    (steps = 1) =>
      setState((currentState) => ({
        history: currentState.history,
        current: Math.max(0, currentState.current - steps),
      })),
    [],
  );

  const forward = React.useCallback(
    (steps = 1) =>
      setState((currentState) => ({
        history: currentState.history,
        current: Math.min(
          currentState.history.length - 1,
          currentState.current + steps,
        ),
      })),
    [],
  );

  const handlers = React.useMemo(
    () => ({ set, forward, back }),
    [set, forward, back],
  );

  return [state.history[state.current] as T, handlers, state];
}
