import { VerifyMemberInvite } from '@clubsoul/api-contracts';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@clubsoul/ui';
import { getApiError } from '@clubsoul/ui/utils/apiError';
import { createFileRoute } from '@tanstack/react-router';

import AuthLayout from '@/auth/auth-layout';
import CreateMemberAccountForm from '@/auth/create-member-account-form';
import FullscreenError from '@/components/fullscreen-error';
import { Page, PageContent } from '@/components/layout';
import { publicClient } from '@/lib/axios';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/create-member-account')({
  component: CreateMemberAccountPage,
  errorComponent: ({ error }) => (
    <AuthLayout>
      <FullscreenError message={getApiError(error).message ?? undefined} />
    </AuthLayout>
  ),
  loaderDeps: ({ search: { token } }) => ({ token }),
  loader: ({ deps: { token } }) =>
    publicClient.post<VerifyMemberInvite>('/v1/members/invite/verify', {
      token,
    }),
  validateSearch: z.object({
    token: z.string(),
  }),
});

function CreateMemberAccountPage() {
  const { token } = Route.useSearch();
  const { clubName, firstName, lastName, email, birthDate, minimumAge } =
    Route.useLoaderData();

  return (
    <AuthLayout>
      <Page>
        <PageContent className="mt-8 rounded-t-md bg-white px-4 pb-14">
          <Card>
            <CardHeader>
              <CardTitle>Mitgliedskonto erstellen</CardTitle>
              <CardDescription>
                Erstelle deinen Konto und trete {clubName} bei
              </CardDescription>
            </CardHeader>

            <CardContent>
              <CreateMemberAccountForm
                firstName={firstName}
                lastName={lastName}
                email={email}
                birthDate={birthDate}
                minimumAge={minimumAge}
                token={token}
              />
            </CardContent>
          </Card>
        </PageContent>
      </Page>
    </AuthLayout>
  );
}
