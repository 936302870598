import { PropsWithChildren } from 'react';

export default function Header({ children }: PropsWithChildren) {
  return (
    <header className="p-4 md:px-0">
      <div className="md:container md:max-w-[1024px]">
        <div className="flex items-center justify-between">
          <img src="logo_black.svg" className="h-10 w-10" />
          <div>{children}</div>
        </div>
      </div>
    </header>
  );
}
