import { ResetPasswordRequest } from '@clubsoul/api-contracts';
import { useMutation } from '@tanstack/react-query';

import { publicClient } from '@/lib/axios';

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: (data: ResetPasswordRequest) =>
      publicClient.post('/v1/auth/reset-password', data),
  });
};
