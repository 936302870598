import { Alert, AlertDescription, AlertTitle } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';

export const Route = createFileRoute('/_app/profile/security/password-reset')({
  component: PasswordResetPage,
});

function PasswordResetPage() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="grow text-center">Passwort ändern</PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <Alert variant={'info'}>
          <AlertTitle>Du hast Post!</AlertTitle>
          <AlertDescription className="pb-6 pt-2">
            Bitte überprüfe dein E-Mail-Postfach, wir haben dir eine E-Mail mit
            Anweisungen zur Passwortänderung gesendet.
          </AlertDescription>
          <AlertDescription>
            Folge den Schritten in der E-Mail, um dein Passwort zu
            aktualisieren.
          </AlertDescription>
        </Alert>
      </PageContent>

      <PageContent className="text-sm">
        <div className="pb-6">
          Sie können die E-Mail immer noch nicht finden?
          <br />
          Kein Problem!
        </div>
        <div className="pt-4">
          Sie können die E-Mail nach Ablauf der verbleibenden Zeit erneut senden
        </div>
      </PageContent>
    </div>
  );
}
