import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { UpdateUserDetailsSchema, UserDetails } from '@clubsoul/api-contracts';
import {
  Button,
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
  FormCalendar,
  FormCountryPicker,
  FormTextInput,
  FormWithMethods,
  SubmitButton,
} from '@clubsoul/ui';
import { getApiError } from '@clubsoul/ui/utils/apiError';
import { mapToDirtyFields } from '@clubsoul/ui/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';

import { useUpdateUserDetails } from '@/api/user/useUpdateUserDetails';
import { toast } from 'sonner';
import { z } from 'zod';

type EditProfileDetailsModalProps = {
  data: UserDetails;
};

const UIFormSchema = UpdateUserDetailsSchema.extend({
  username: z.string(),
  email: z.string(),
});

type UIFormFields = z.infer<typeof UIFormSchema>;

export function EditProfileDetailsModal(props: EditProfileDetailsModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Credenza open={isOpen} onOpenChange={setIsOpen}>
      <CredenzaTrigger asChild>
        <Button>Bearbeiten</Button>
      </CredenzaTrigger>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>Bearbeiten</CredenzaTitle>
        </CredenzaHeader>
        <ProfileDetailsForm {...props} onClose={() => setIsOpen(false)} />
      </CredenzaContent>
    </Credenza>
  );
}

type ProfileDetailsFormProps = EditProfileDetailsModalProps & {
  onClose: () => void;
};

function ProfileDetailsForm(props: ProfileDetailsFormProps) {
  const { data, onClose } = props;
  const [isUpdating, setIsUpdating] = useState(false);

  const { mutateAsync: update } = useUpdateUserDetails();

  const form = useForm<UIFormFields>({
    defaultValues: {
      username: data.username,
      email: data.email,
      birthDate: data.birthDate,
      address: {
        street: data.address.street ?? '',
        postalCode: data.address.postalCode ?? '',
        city: data.address.city ?? '',
        country: data.address.country ?? 'DE',
        co: data.address.co ?? '',
      },
    },
    resolver: zodResolver(UIFormSchema),
  });

  const handleSubmit = async (data: UIFormFields) => {
    setIsUpdating(true);
    try {
      const { username, email, ...apiFields } = data;
      await update(mapToDirtyFields(apiFields, form.formState.dirtyFields));
      toast.success('Profil aktualisiert');
      onClose();
    } catch (error) {
      toast.error(
        getApiError(error)?.message ??
          'Die Anfrage konnte nicht geschickt werden',
      );
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <FormWithMethods {...form} onSubmit={handleSubmit}>
      <CredenzaBody className="space-y-4">
        <FormTextInput<UIFormFields>
          name="username"
          label="Benutzername"
          disabled
        />
        <FormTextInput<UIFormFields>
          name="address.street"
          label="Straße + Nr."
          required
        />
        <div className="flex flex-col gap-4 sm:flex-row">
          <FormTextInput<UIFormFields>
            name="address.postalCode"
            label="PLZ"
            className="grow"
            required
          />
          <FormTextInput<UIFormFields>
            name="address.city"
            label="Stadt"
            className="grow"
            required
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <FormTextInput<UIFormFields>
            name="address.co"
            label="CO"
            className="grow basis-1/2"
          />
          <FormCountryPicker<UIFormFields>
            name="address.country"
            label="Land"
            className="grow basis-1/2"
            disabled
          />
        </div>
        <FormTextInput<UIFormFields> name="email" label="E-Mail" disabled />
        <FormCalendar<UIFormFields>
          name="birthDate"
          label="Geburtsdatum"
          initialFocus
          captionLayout="dropdown"
          fromYear={1900}
          toYear={new Date().getFullYear() - 18}
        />
      </CredenzaBody>
      <CredenzaFooter>
        <SubmitButton
          isLoading={isUpdating}
          disabled={Object.keys(form.formState.dirtyFields).length === 0}
        >
          Änderungen speichern
        </SubmitButton>
      </CredenzaFooter>
    </FormWithMethods>
  );
}
