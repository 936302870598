import { AWSPresignedPOSTUpload } from '@clubsoul/api-contracts';

import axios, { AxiosRequestConfig } from 'axios';

export const apiClient = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  withCredentials: true,
});

export const uploadToS3 = async ({
  url,
  file,
  fields,
  onUploadProgress,
}: AWSPresignedPOSTUpload & {
  file: File;
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
}) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([key, value]) => {
    formData.set(key, value);
  });

  formData.set('file', file);

  return axios.postForm(url, formData, {
    onUploadProgress,
  });
};
