import { Avatar, AvatarFallback, AvatarImage } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import { useAuth } from '@/auth/useAuth';
import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { QRCode } from '@/components/qr-code';

export const Route = createFileRoute('/_app/check-in/qr-code')({
  component: QRCodePage,
});

function QRCodePage() {
  const { session } = useAuth();

  if (!session) {
    return null;
  }

  const { firstName, lastName, profilePic, memberId } = session;

  return (
    <div className="p-4">
      <PageHeader className="space-y-4">
        <div className="flex items-center">
          <BackButton />
          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            QR-Code
          </PageTitle>
        </div>

        <div className="flex flex-col items-center gap-4">
          <Avatar className="h-20 w-20">
            <AvatarImage src={profilePic} />
            <AvatarFallback>
              {firstName?.charAt(0)}
              {lastName?.charAt(0)}
            </AvatarFallback>
          </Avatar>
          {/* <img
            src={imageUrl}
            alt="profile-img"
            className="mx-auto rounded-full"
          /> */}
          <h4 className="text-xl font-semibold">
            {firstName} {lastName}
          </h4>
          {/* <p className="pb-2">{number}</p> */}
          {/* <span className="rounded-full border bg-green-500 px-3 py-1 text-sm text-white">
            Aktiv
          </span> */}
        </div>
      </PageHeader>

      <PageContent className="mt-12 flex flex-col items-center text-center">
        <QRCode value={memberId!} />

        <p className="mt-4">Bitte zeige diesen QR-Code bei der Abgabe vor.</p>
      </PageContent>
    </div>
  );
}
