import React from 'react';

import { Button, ButtonProps } from '@clubsoul/ui';
import type {
  LinkProps,
  RegisteredRouter,
  RoutePaths,
} from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';

export type LinkButtonProps<
  TRouter extends RegisteredRouter = RegisteredRouter,
  TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
  TMaskTo extends string = '',
> = Pick<
  LinkProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>,
  'params' | 'search' | 'to'
> &
  Omit<ButtonProps, 'onClick'> & {
    suffix?: React.ReactNode;
    prefix?: React.ReactNode;
  };

export const LinkButton = <
  TRouter extends RegisteredRouter = RegisteredRouter,
  TFrom extends RoutePaths<TRouter['routeTree']> | string = string,
  TTo extends string = '',
  TMaskFrom extends RoutePaths<TRouter['routeTree']> | string = TFrom,
  TMaskTo extends string = '',
>({
  params,
  search,
  to,
  prefix,
  suffix,
  children,
  ...props
}: LinkButtonProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>) => {
  if (props.disabled) {
    return (
      <Button {...props}>
        {prefix}
        <span>{children}</span>
        {suffix}
      </Button>
    );
  }

  return (
    <Button asChild {...props}>
      <Link to={to} params={params} search={search}>
        {prefix}
        {children}
        {suffix}
      </Link>
    </Button>
  );
};
