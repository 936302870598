import { SETTINGS_ENABLED } from '@clubsoul/const';
import { Button } from '@clubsoul/ui';
import { createFileRoute, useRouter } from '@tanstack/react-router';

import useUser from '@/auth/useUser';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { LinkButton, LinkButtonProps } from '@/components/link-button';
import { ProfileAvatar } from '@/components/profile-avatar';
import { auth } from '@/lib/firebase';
import { signOut } from 'firebase/auth';
import {
  ChevronRight,
  HelpCircle,
  LogOut,
  LucideIcon,
  Settings,
  User,
} from 'lucide-react';

export const Route = createFileRoute('/_app/profile/')({
  component: ProfilePage,
});

function ProfilePage() {
  const router = useRouter();
  const { firstName, lastName, email, profilePic } = useUser() ?? {};

  const handleLogout = async () => {
    await signOut(auth);
    router.navigate({ to: '/login' });
  };

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8 text-center">
        <PageTitle className="h-10">Profil</PageTitle>

        <div className="flex flex-col items-center justify-center space-y-2">
          <div className="h-32 w-32">
            <ProfileAvatar url={profilePic} />
          </div>

          <div className="space-y-1">
            <h4 className="text-xl font-semibold">
              {firstName} {lastName}
            </h4>
            <p className="pb-2 text-sm">{email}</p>
          </div>
        </div>
      </PageHeader>

      <PageContent className="space-y-2">
        <h5 className="text-lg font-semibold">Account</h5>

        <ProfileLink
          icon={User}
          label="Persönliche Daten"
          to="/profile/details"
        />
        {SETTINGS_ENABLED.enabled && (
          <ProfileLink
            icon={Settings}
            label="Einstellungen"
            to="/profile/settings"
          />
        )}
        {/* <ProfileLink
          icon={UserSquare}
          label="Mitgliedschaft"
          to="/memberships"
        />
        <ProfileLink icon={CreditCard} label="Zahlungen" to="/payments" />
        <ProfileLink icon={Lock} label="Sicherheit" to="/profile/security" /> */}
      </PageContent>

      <PageContent className="space-y-2">
        <h5 className="text-lg font-semibold">Mehr</h5>

        {/* <ProfileLink
          icon={Trash2}
          label="Account löschen"
          to="/profile/delete"
        /> */}
        <ProfileLink icon={HelpCircle} label="Support" to="/support" />
        <Button
          variant="ghost"
          className="-px-2 flex w-full items-center gap-2 text-left"
          onClick={handleLogout}
        >
          <LogOut size={24} />
          <span className="grow">Ausloggen</span>
          <ChevronRight size={24} />
        </Button>
      </PageContent>
    </div>
  );
}

type ProfileLinkProps = {
  icon: LucideIcon;
  label: string;
  to: LinkButtonProps['to'];
  disabled?: boolean;
};

function ProfileLink(props: ProfileLinkProps) {
  const { icon: Icon, label, to } = props;

  return (
    <LinkButton
      variant="ghost"
      to={to}
      className="-px-2 flex items-center gap-2"
    >
      <Icon size={24} />
      <span className="grow">{label}</span>
      <ChevronRight size={24} />
    </LinkButton>
  );
}
