import { PropsWithChildren } from 'react';

import { cn } from '@clubsoul/ui/lib/utils';

type LayoutProps = PropsWithChildren<{
  className?: string;
}>;

export function Page(props: LayoutProps) {
  return (
    <main className={cn('container max-w-screen-sm px-0', props.className)}>
      {props.children}
    </main>
  );
}

export function PageHeader(props: LayoutProps) {
  return <header className={props.className}>{props.children}</header>;
}

export function PageTitle(props: LayoutProps) {
  return (
    <h1 className={cn('text-2xl font-semibold', props.className)}>
      {props.children}
    </h1>
  );
}

export function PageContent(props: LayoutProps) {
  return (
    <div className={cn('container px-0', props.className)}>
      {props.children}
    </div>
  );
}

export function PageContentTitle(props: LayoutProps) {
  return (
    <h4 className={cn('text-lg font-semibold', props.className)}>
      {props.children}
    </h4>
  );
}

export function PageFooter(props: LayoutProps) {
  return (
    <footer className={cn('mt-4', props.className)}>{props.children}</footer>
  );
}

export function PageSection(props: LayoutProps) {
  return (
    <section className={cn('mt-4', props.className)}>{props.children}</section>
  );
}
