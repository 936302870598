import { Alert, AlertDescription, AlertTitle, Button } from '@clubsoul/ui';
import { useCountdownTimer } from '@clubsoul/ui/hooks';
import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';

export const Route = createFileRoute('/_app/profile/delete/resend')({
  component: Page,
});

const TIMER = 59;

function Page() {
  const { label: timeLabel, isRunning } = useCountdownTimer(TIMER);

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="grow text-center">Account löschen</PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <Alert variant={'info'}>
          <AlertTitle>Du hast Post!</AlertTitle>
          <AlertDescription>
            Bitte überprüfe dein E-Mail-Postfach, wir haben dir eine E-Mail mit
            Anweisungen zur Löschung deines Accounts gesendet.
          </AlertDescription>
          <AlertDescription className="pt-4">
            Folge den Schritten in der E-Mail, um deinen Account zu löschen.
          </AlertDescription>
        </Alert>
      </PageContent>

      <PageContent className="text-sm">
        <div className="pb-6">
          Sie können die E-Mail immer noch nicht finden?
          <br />
          Kein Problem!
        </div>
        <Button className="w-full" disabled={isRunning}>
          {isRunning ? timeLabel : 'Löschung beantragen'}
        </Button>
        <div className="pt-4">
          Sie können die E-Mail nach Ablauf der verbleibenden Zeit erneut senden
        </div>
      </PageContent>
    </div>
  );
}
