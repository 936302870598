import { useEffect, useState } from 'react';

/**
 * Convert seconds to "mm:ss" format
 */
function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export function useCountdownTimer(start = 60) {
  const [seconds, setSeconds] = useState<number>(start);

  useEffect(() => {
    const handler = () => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          }
          clearInterval(intervalId);
          return 0;
        });
      }
    };
    const intervalId = setInterval(handler, 1000);
    return () => clearInterval(intervalId);
  }, [seconds]);

  const resetTimer = () => setSeconds(start);

  return {
    time: seconds,
    label: formatTime(seconds),
    isRunning: seconds !== 0,
    resetTimer,
  };
}
