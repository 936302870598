'use client';

import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from 'recharts';

import { Card, CardContent, CardDescription, CardFooter } from '../ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../ui/chart';

type RadialChartProps = {
  title?: string;
  description?: string;
  note?: string;
  config: ChartConfig;
  data: any[];
};

export function RadialChart(props: RadialChartProps) {
  const { title, description, note, config, data } = props;

  return (
    <Card className="flex flex-col">
      <CardContent className="flex flex-1 items-center pb-0">
        <ChartContainer
          config={config}
          className="mx-auto -mb-36 aspect-square w-full max-w-[350px]"
        >
          <RadialBarChart
            data={data}
            endAngle={180}
            innerRadius={150}
            outerRadius={180}
          >
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 70}
                          className="fill-foreground text-lg font-semibold"
                        >
                          {title}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 25}
                          className="fill-muted-foreground text-3xl"
                        >
                          {description}
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </PolarRadiusAxis>
            <RadialBar
              dataKey="onWay"
              fill="var(--color-onWay)"
              stackId="a"
              cornerRadius={'50%'}
              className="stroke-transparent stroke-2"
            />
            <RadialBar
              dataKey="delivered"
              stackId="a"
              cornerRadius={'50%'}
              fill="var(--color-delivered)"
              className="stroke-transparent stroke-2"
            />
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="justify-end text-sm italic">
        <CardDescription>{note}</CardDescription>
      </CardFooter>
    </Card>
  );
}
