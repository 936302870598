import { MouseEvent } from 'react';

import { Link, LinkProps } from '@tanstack/react-router';

import { Home, LucideIcon, QrCode, User } from 'lucide-react';
import { toast } from 'sonner';

export function NavBar() {
  return (
    <section className="fixed bottom-4 left-[50%] translate-x-[-50%] rounded-full bg-stone-100 p-1">
      <div className="flex gap-[1px]">
        <NavBarItem icon={Home} url="/" />
        <NavBarItem icon={QrCode} url="/check-in" />
        <NavBarItem icon={User} url="/profile" />
      </div>
    </section>
  );
}

type NavBarItemProps = {
  icon: LucideIcon;
  url: LinkProps['to'];
  isPreview?: boolean;
};

function NavBarItem(props: NavBarItemProps) {
  const { icon: Icon, url, isPreview } = props;

  const handlePreview = (e: MouseEvent<'a'>) => {
    if (!isPreview) {
      return;
    }

    e.preventDefault();
    toast.info(
      <div>
        <h5 className="mb-1 text-base font-semibold">
          🚀&nbsp;&nbsp;Diese Funktion kommt bald
        </h5>
        <p className="text-sm">
          Wir arbeiten momentan an die Erweiterung der Funktionen, wenn du
          Feedback oder Wünsche hast, schick uns gerne eine{' '}
          <a className="underline" href="mailto:info@clubsoul.de">
            email
          </a>
          .
        </p>
      </div>,
      { closeButton: true },
    );
  };

  return (
    <Link
      to={url}
      onClick={handlePreview}
      className="flex h-10 w-16 flex-col items-center justify-center gap-y-1 bg-primary transition-colors first:rounded-bl-full first:rounded-tl-full last:rounded-br-full last:rounded-tr-full hover:bg-primary/90"
    >
      <Icon size={20} className="stroke-white" />
    </Link>
  );
}
