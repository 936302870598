import { UserDetails, UserSettings } from '@clubsoul/api-contracts';
import { queryOptions } from '@tanstack/react-query';

import { authClient } from '@/lib/axios';

import { userKeys } from './user.keys';

export const userDetailsQueryOptions = () =>
  queryOptions({
    queryKey: userKeys.getDetails(),
    queryFn: () => authClient.get<UserDetails>('/v1/users/'),
  });

export const userSettingsQueryOptions = () =>
  queryOptions({
    queryKey: userKeys.settings(),
    queryFn: () => authClient.get<UserSettings>('/v1/users/settings'),
  });
