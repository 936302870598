import { PURCHASES_ENABLED } from '@clubsoul/const';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  CardDescription,
  CardTitle,
} from '@clubsoul/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { usePurchase } from '@/api/purchase/usePurchase';
import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { Star } from 'lucide-react';

export const Route = createFileRoute('/_app/purchases/$id')({
  beforeLoad: () => {
    if (!PURCHASES_ENABLED.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: Page,
});

function Page() {
  const { id } = Route.useParams();

  const { name } = usePurchase(id);

  return (
    <div className="space-y-4 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            {name}
          </PageTitle>
        </div>

        <div className="flex flex-col items-center gap-2">
          <Avatar className="h-36 w-36">
            <AvatarImage src={''} />
            <AvatarFallback>HB</AvatarFallback>
          </Avatar>

          <div className="space-y-1 text-center">
            <h5 className="font-medium">{name}</h5>
            <span className="font-light text-gray-800">Indica</span>
          </div>
        </div>
      </PageHeader>

      <PageContent>
        <Card className="p-4">
          <CardTitle className="pb-2 text-base">Beschreibung</CardTitle>

          <CardDescription>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt.
          </CardDescription>
        </Card>
      </PageContent>

      <PageContent>
        <Card className="p-4">
          <CardTitle className="pb-4 text-base">
            Weitere Informationen
          </CardTitle>

          <div className="grid grid-cols-2 gap-4">
            <Cell name="Kategorie" value="Cannabis" />
            <Cell name="Typ" value="Indica" />
            <Cell name="THC-Gehalt" value="18%" />
            <Cell name="CBD-Gehalt" value="10%" />
            <Cell name="Geschmacksprofil" value="Süß, Pfeffer, Pinien" />
          </div>
        </Card>
      </PageContent>

      <PageContent>
        <Card className="p-4">
          <CardTitle className="pb-2 text-base">Rating</CardTitle>

          <div className="flex flex-row items-center gap-1">
            <Star color="green" />
            <Star color="green" />
            <Star color="green" />
            <Star />
            <Star />

            <span className="pl-2 text-gray-800">3/5 (50)</span>
          </div>
        </Card>
      </PageContent>
    </div>
  );
}

type CellProps = {
  name: string;
  value: string;
};

function Cell(props: CellProps) {
  const { name, value } = props;

  return (
    <div className="space-y-2 text-sm">
      <h6 className="font-semibold">{name}</h6>
      <p>{value}</p>
    </div>
  );
}
