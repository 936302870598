import {
  JoinMemberWaitlistSchema,
  OptionalPhoneNumberSchema,
  WeedGrammsSchema,
  createBirthDateSchema,
} from '@clubsoul/api-contracts';
import {
  Button,
  Form,
  FormCalendar,
  FormCheckbox,
  FormNumberInput,
  FormPhoneNumberInput,
  FormTextInput,
} from '@clubsoul/ui';
import { getApiError } from '@clubsoul/ui/utils/apiError';
import { preprocessEmptyStringToUndefined } from '@clubsoul/ui/utils/validation';

import { useJoinWaitlist } from '@/api/members/useJoinWaitlist';
import { subYears } from 'date-fns';
import { toast } from 'sonner';
import { z } from 'zod';

type JoinWaitlistFormProps = {
  clubId: string;
  minimumAge: number;
  onSent(): void;
};

const UIJoinWaitlistSchema = JoinMemberWaitlistSchema.extend({
  phoneNumber: preprocessEmptyStringToUndefined(OptionalPhoneNumberSchema),
  expectedQuantity: WeedGrammsSchema,
  privacyPolicyAccepted: z.boolean().refine((isTrue) => isTrue, {
    message: 'Pflichtfeld',
  }),
  ageRestricctionAccepted: z
    .boolean()
    .refine((isTrue) => isTrue, { message: 'Pflichtfeld' }),
});

type UIJoinMemberWaitlist = z.infer<typeof UIJoinWaitlistSchema>;

export default function JoinWaitlistForm({
  clubId,
  minimumAge,
  onSent,
}: JoinWaitlistFormProps) {
  const { mutateAsync: join } = useJoinWaitlist();

  const handleSubmit = async ({
    firstName,
    lastName,
    email,
    birthDate,
    phoneNumber,
    expectedQuantity,
    clubId,
  }: UIJoinMemberWaitlist) => {
    try {
      await join({
        firstName,
        lastName,
        email,
        birthDate,
        phoneNumber,
        expectedQuantity,
        clubId,
      });
      onSent();
    } catch (e) {
      toast.error(
        getApiError(e)?.message ?? 'Die Anfrage konnte nicht geschickt werden',
      );
    }
  };

  return (
    <Form
      schema={UIJoinWaitlistSchema.extend({
        birthDate: createBirthDateSchema(minimumAge),
      })}
      onSubmit={handleSubmit}
      defaultValues={getDefaultValues(clubId)}
    >
      <FormTextInput name="firstName" label="Vorname" required />
      <FormTextInput name="lastName" label="Nachname" required />
      <FormTextInput name="email" label="E-Mail" type="email" required />
      <FormCalendar
        name="birthDate"
        label="Geburtsdatum"
        captionLayout="dropdown"
        fromYear={1900}
        toYear={subYears(new Date(), minimumAge).getFullYear()}
        description={`Das Mindestalter, um dem Club beitreten zu können, ist ${minimumAge}`}
        required
      />
      <FormPhoneNumberInput name="phoneNumber" label="Telefonnummer" />
      <FormNumberInput
        name="expectedQuantity"
        label="Erwarteter monatlicher Konsum"
        rightSlot={<span className="text-slate-800">Gramm</span>}
        placeholder="25"
        required
      />
      <div className="flex flex-col gap-4 pt-4">
        <FormCheckbox
          name="ageRestricctionAccepted"
          label={`Ich bestätige dass alle meine Angaben Korrekt sind und mindestens ${minimumAge} Jahre alt bin.`}
          className="text-slate-700"
        />
        <FormCheckbox
          name="privacyPolicyAccepted"
          label={
            <span>
              Ich habe die{' '}
              <a
                href={import.meta.env.VITE_AGB_URL}
                className="underline"
                target="_blank"
              >
                Nutzungsbedingungen
              </a>
              <span> und die </span>
              <a
                href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                className="underline"
                target="_blank"
              >
                Datenschutzrichtlinien
              </a>{' '}
              gelesen und akzeptiere die.
            </span>
          }
          className="text-slate-700"
        />
        <Button className="mt-2 w-full">Absenden</Button>
      </div>
    </Form>
  );
}

function getDefaultValues(clubId: string): Partial<UIJoinMemberWaitlist> {
  return {
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    phoneNumber: '',
    clubId,
    ageRestricctionAccepted: false,
    privacyPolicyAccepted: false,
  };
}
