import { PropsWithChildren } from 'react';

import { cn } from '@clubsoul/ui/lib/utils';

import { AlertTriangle } from 'lucide-react';

type InlineErrorProps = {
  className?: string;
};

export default function InlineError({
  children,
  className,
}: PropsWithChildren<InlineErrorProps>) {
  return (
    <div
      className={cn('flex items-center text-sm text-destructive', className)}
    >
      <AlertTriangle className="mr-2 h-4 w-4 flex-shrink-0" />
      <span>{children}</span>
    </div>
  );
}
