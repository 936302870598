import { MemberMembership } from '@clubsoul/api-contracts';
import { Card } from '@clubsoul/ui';
import { cn } from '@clubsoul/ui/lib/utils';

import { CalendarClock, Coins, LucideIcon, Weight } from 'lucide-react';

export type MembershipCardProps = MemberMembership;

export function MembershipCard(props: MembershipCardProps) {
  const { name, price, currency, interval } = props;

  return (
    <Card className="flex flex-col gap-4 p-4">
      <div className="grid grid-cols-2 gap-4">
        <Cell title={name} icon={CalendarClock} description={interval} />
        <Cell
          className="col-start-1"
          title={`20 Gramm`}
          icon={Weight}
          description={'Menge'}
        />
        <Cell
          title={`${price}${currency}`}
          icon={Coins}
          description={'Preis'}
          className="flex flex-col items-end"
        />
      </div>
    </Card>
  );
}

type CellProps = {
  title: string;
  icon: LucideIcon;
  description: string;
  className?: string;
};

function Cell(props: CellProps) {
  const { title, icon: Icon, description, className } = props;

  return (
    <article className={cn('flex flex-col gap-1', className)}>
      <h6 className="text-lg font-semibold">{title}</h6>
      <span className="inline-flex items-center gap-x-2 text-sm capitalize">
        <Icon size={16} />
        {description.toLowerCase()}
      </span>
    </article>
  );
}
