import { createFileRoute } from '@tanstack/react-router';

import AuthLayout from '@/auth/auth-layout';
import { ErrorVerifyingCard } from '@/auth/verification-cards';
import AccountVerifiedCard from '@/auth/verified-card';
import { Page, PageContent } from '@/components/layout';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/account-verified')({
  component: AccountVerified,
  errorComponent: () => (
    <AuthLayout>
      <main className="mt-8 flex justify-center px-4">
        <ErrorVerifyingCard />
      </main>
    </AuthLayout>
  ),
  validateSearch: z.object({
    oobCode: z.string(),
  }),
});

function AccountVerified() {
  const { oobCode } = Route.useSearch();

  return (
    <AuthLayout>
      <Page>
        <PageContent className="mt-8 flex justify-center px-4">
          <AccountVerifiedCard oobCode={oobCode} />
        </PageContent>
      </Page>
    </AuthLayout>
  );
}
