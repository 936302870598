import { PURCHASES_ENABLED } from '@clubsoul/const';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { PurchasePresentation, PurchasesList } from '@/components/purchases';

export const Route = createFileRoute('/_app/purchases/')({
  beforeLoad: () => {
    if (!PURCHASES_ENABLED.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: Page,
});

function Page() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Abgabehistorie
          </PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <PageContentTitle className="pb-4">
          Gesamte Darstellung
        </PageContentTitle>
        Graph
      </PageContent>

      <PageContent>
        <PageContentTitle className="pb-4">Kürzlich abgegeben</PageContentTitle>
        <PurchasesList presentation={PurchasePresentation.DETAILED} />
      </PageContent>
    </div>
  );
}
