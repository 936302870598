import { MemberPurchase } from '@clubsoul/api-contracts';

export function usePurchase(id: MemberPurchase['id']): MemberPurchase {
  return {
    id,
    imageUrl: undefined,
    name: 'Hogs-Breath',
    date: '2024-08-12',
    quantity: 20,
    membershipName: 'Gold',
  };
}
