import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { MemberSession } from '@clubsoul/api-contracts';

export interface AuthContextType {
  isAuthenticated: boolean;
  session: MemberSession | null;
  setSession: Dispatch<SetStateAction<MemberSession | null>>;
  refetchSession: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
