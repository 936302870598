import { useState } from 'react';

import {
  LoginRequest,
  LoginRequestSchema,
  MemberSession,
} from '@clubsoul/api-contracts';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormTextInput,
  Input,
} from '@clubsoul/ui';
import { Link, useRouter } from '@tanstack/react-router';

import InlineError from '@/auth/inline-error';
import { useAuth } from '@/auth/useAuth';
import { authClient } from '@/lib/axios';
import { auth } from '@/lib/firebase';
import { Route } from '@/routes/_auth/login';
import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import {
  UserCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Loader } from 'lucide-react';

const fallback = '/' as const;

export function LoginForm() {
  const router = useRouter();
  const { setSession: setUser } = useAuth();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const search = Route.useSearch();

  const handleSubmit = async (fields: LoginRequest) => {
    setIsLoading(true);
    setError('');

    let credentials: UserCredential;

    try {
      // sign in to firebase
      credentials = await signInWithEmailAndPassword(
        auth,
        fields.email,
        fields.password,
      );
    } catch (e) {
      setIsLoading(false);

      if (e instanceof FirebaseError) {
        switch (e.code) {
          case 'auth/user-disabled':
            setError('Dein Konto wurde gesperrt');
            break;
          case 'auth/invalid-credential':
          default:
            setError('E-Mail oder Passwort ist falsch');
            break;
        }
      }

      return;
    }

    // get token
    const idToken = await credentials.user.getIdToken();

    let customToken: string;

    try {
      // call post-login URL to API to get custom token
      const { data } = await axios.post<{ token: string }>(
        `${import.meta.env.VITE_API_URL}/v1/auth/member/login`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      customToken = data.token;
    } catch (error) {
      setError('Du kannst dich nicht in der member App einloggen');
      setIsLoading(false);
      return;
    }

    try {
      // login with custom token again
      await signInWithCustomToken(auth, customToken);

      const session = await authClient.get<MemberSession>('/v1/auth/session');
      setUser(session);

      router.navigate({ to: search.redirect || fallback });
    } catch (error) {
      setError('Es ist einen Fehler beim einloggen aufgetretten');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form<LoginRequest>
      schema={LoginRequestSchema}
      onSubmit={handleSubmit}
      defaultValues={{ email: '', password: '' }}
    >
      <FormTextInput name="email" label="E-Mail" type="email" />

      <FormField
        name="password"
        render={({ field }) => (
          <FormItem>
            <div className="flex items-center justify-between">
              <FormLabel>Passwort</FormLabel>
              <Link to="/forgot-password">
                <span className="text-sm font-semibold text-primary">
                  Passwort vergessen?
                </span>
              </Link>
            </div>
            <FormControl>
              <Input {...field} type="password" />
            </FormControl>
            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />

      <div>
        <Button disabled={isLoading} className="w-full">
          {isLoading ? <Loader className="animate-spin" /> : 'Einloggen'}
        </Button>
        {error && <InlineError className="mt-2">{error}</InlineError>}
      </div>
    </Form>
  );
}
