import { PropsWithChildren } from 'react';

import { Button } from '@clubsoul/ui';
import { Link } from '@tanstack/react-router';

import Header from '@/components/header';

export default function AuthLayout({ children }: PropsWithChildren) {
  return (
    <>
      <Header>
        <Button asChild variant="outline" size="sm">
          <Link to="/login">Einloggen</Link>
        </Button>
      </Header>
      {children}
    </>
  );
}
