import { ReactNode, useId } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components';
import { cn } from '../../lib/utils';

type FormCheckboxProps<T extends FieldValues> = {
  name: Path<T>;
  label: ReactNode;
  disabled?: boolean;
  className?: string;
  description?: string;
  required?: boolean;
};

export function FormCheckbox<T extends FieldValues>({
  name,
  label,
  disabled,
  className,
  description,
  required = false,
}: FormCheckboxProps<T>) {
  const id = useId();
  const { control } = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <div className={cn('flex gap-2', className)}>
            <FormControl>
              <Checkbox
                id={id}
                ref={field.ref}
                checked={field.value as boolean}
                onCheckedChange={field.onChange}
                onBlur={field.onBlur}
                disabled={disabled}
                required={required}
              />
            </FormControl>
            <FormLabel htmlFor={id} className="leading-4">
              {label} {required && '*'}
            </FormLabel>
          </div>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  );
}
