import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

import { Page } from '@/components/layout';
import { NavBar } from '@/components/nav-bar';
import { VerifyEmail } from '@/components/verify-email';
import { auth } from '@/lib/firebase';

export const Route = createFileRoute('/_app')({
  beforeLoad: async ({ location }) => {
    if (!auth.currentUser) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: Layout,
});

function Layout() {
  return (
    <Page className="pb-16">
      <VerifyEmail />
      <Outlet />
      <NavBar />
    </Page>
  );
}
