import { Card, CardDescription, CardTitle } from '@clubsoul/ui';
import { cn } from '@clubsoul/ui/lib/utils';
import { Link } from '@tanstack/react-router';

import { useMemberships } from '@/api/membership/useMemberships';

import { MembershipCard } from './membership-card';

type MembershipsListProps = {
  orientation?: 'horizontal' | 'vertical';
};

export function MembershipsList(props: MembershipsListProps) {
  const { orientation = 'vertical' } = props;
  const isEmpty = false;
  const data = useMemberships();

  if (isEmpty) {
    return (
      <Card className="flex flex-col gap-4 px-4 py-8 text-center">
        <CardTitle className="leading-8">Keine Beiträge</CardTitle>
        <CardDescription>
          Zurzeit sind keine Beiträge vorhanden.
        </CardDescription>
      </Card>
    );
  }

  if (orientation === 'horizontal') {
    return (
      <div className="overflow-x-scroll pb-4">
        <ul className={cn('inline-flex gap-2')}>
          {data.map((membership) => (
            <li
              key={membership.id}
              className={cn({
                'w-full': data.length === 1,
                'w-[300px]': data.length > 1,
              })}
            >
              <Link to="/memberships/$id" params={{ id: membership.id }}>
                <MembershipCard {...membership} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <ul className={cn('flex flex-col gap-2')}>
      {data.map((membership) => (
        <li key={membership.id}>
          <Link to="/memberships/$id" params={{ id: membership.id }}>
            <MembershipCard {...membership} />
          </Link>
        </li>
      ))}
    </ul>
  );
}
