import { Card, CardDescription, CardHeader, CardTitle } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { LinkButton } from '@/components/link-button';
import { ChangePasswordRequestForm } from '@/form/change-password-request-form';

export const Route = createFileRoute('/_app/profile/security/')({
  component: ProfileSecurityPage,
});

function ProfileSecurityPage() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="flex items-center">
        <BackButton />

        <PageTitle className="-z-10 ml-[-40px] grow text-center">
          Sicherheit
        </PageTitle>
      </PageHeader>

      <PageContent className="space-y-8">
        <Card className="space-y-4 p-4">
          <CardHeader className="space-y-2 p-0">
            <CardTitle className="text-md">Passwort</CardTitle>
            <CardDescription>
              Klicke auf „Passwort ändern“ und folge den Anweisungen in der
              E-Mail, die wir an deine registrierte Adresse senden, um dein
              Passwort zu aktualisieren.
            </CardDescription>
          </CardHeader>

          <ChangePasswordRequestForm />
        </Card>

        <Card className="space-y-6 p-4">
          <CardHeader className="space-y-2 p-0">
            <CardTitle className="text-md">
              Zwei-Faktor Authentifizierung
            </CardTitle>
            <CardDescription>
              Die Zwei-Faktor-Authentifizierung bietet eine zusätzliche
              Sicherheitsebene, indem sie neben deinem Passwort einen zweiten,
              zeitlich begrenzten Code erfordert. Dies schützt dein Konto auch
              dann, wenn dein Passwort kompromittiert sein sollte.
            </CardDescription>
          </CardHeader>

          <LinkButton to="/profile/security/2fa">Aktivieren</LinkButton>
        </Card>
      </PageContent>
    </div>
  );
}
