import { Alert, AlertDescription, AlertTitle, Button } from '@clubsoul/ui';
import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';

export const Route = createFileRoute('/_app/support')({
  component: Page,
});

function Page() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="flex items-center">
        <BackButton />
        <PageTitle className="-z-10 ml-[-40px] grow text-center">
          Support
        </PageTitle>
      </PageHeader>

      <PageContent>
        <Alert variant={'info'}>
          <AlertTitle>Kontaktiere unseren Support</AlertTitle>
          <AlertDescription>
            Bei Fragen oder Problemen, sende eine E-Mail an{' '}
            <a href={'mailto:info@clubsoul.de'}>
              <b>info@clubsoul.de</b>
            </a>
            .
          </AlertDescription>
          <AlertDescription className="pt-4">
            Beschreibe dein Anliegen detailliert, inklusive Fehlermeldungen und
            Gerätetyp. Screenshots helfen uns, dir schneller zu helfen.
          </AlertDescription>
        </Alert>
      </PageContent>

      <PageContent>
        <PageContentTitle>Kurz und klar:</PageContentTitle>

        <ul className="list-disc space-y-2 pb-8 pl-4 pt-2 text-sm">
          <li>Was ist das Problem?</li>
          <li>Wann trat es auf?</li>
          <li>Welches Gerät verwendest du?</li>
        </ul>

        <Button className="w-full" asChild>
          <a href={'mailto:info@clubsoul.de'}>Support kontaktieren</a>
        </Button>
      </PageContent>
    </div>
  );
}
