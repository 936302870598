import {
  ResetPasswordRequest,
  ResetPasswordRequestSchema,
} from '@clubsoul/api-contracts';
import { Form, FormTextInput, SubmitButton } from '@clubsoul/ui';
import { useNavigate } from '@tanstack/react-router';

import { useForgotPassword } from '@/api/auth/useForgotPassword';

export default function ForgotPasswordForm() {
  const navigate = useNavigate({ from: '/forgot-password' });
  const { mutateAsync: resetPassword, isPending } = useForgotPassword();

  const handleSubmit = async (data: ResetPasswordRequest) => {
    try {
      await resetPassword(data);
      navigate({ to: '/login' });
    } catch (e) {}
  };

  return (
    <Form
      schema={ResetPasswordRequestSchema}
      onSubmit={handleSubmit}
      defaultValues={{ email: '', origin: 'member' }}
    >
      <FormTextInput label="E-Mail" type="email" name="email" />
      <SubmitButton className="w-full" isLoading={isPending}>
        Zurücksetzen
      </SubmitButton>
    </Form>
  );
}
