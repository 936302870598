import { UpdateUserDetails } from '@clubsoul/api-contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { authClient } from '@/lib/axios';

import { userKeys } from './user.keys';

export const useUpdateUserDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateUserDetails) => authClient.put('/v1/users/', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.getDetails() });
    },
  });
};
