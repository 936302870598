'use client';

import { FileUpload, ImageUploadRequest } from '@clubsoul/api-contracts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/auth/useAuth';
import { authClient } from '@/lib/axios';

import { userKeys } from './user.keys';

export function useDeleteUserAvatar() {
  const queryClient = useQueryClient();
  const { refetchSession } = useAuth();

  return useMutation({
    mutationFn: () => authClient.delete<void>('/v1/users/avatar'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.getDetails() });
      refetchSession();
    },
  });
}
export function useUploadUserAvatar() {
  const queryClient = useQueryClient();
  const { refetchSession } = useAuth();

  return useMutation({
    mutationFn: (file: ImageUploadRequest) =>
      authClient.post<FileUpload>(`/v1/users/avatar`, file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.getDetails() });
      refetchSession();
    },
  });
}
