import { PasswordSchema } from '@clubsoul/api-contracts';
import { Button, Form, FormTextInput } from '@clubsoul/ui';
import { useRouter } from '@tanstack/react-router';

import { z } from 'zod';

const NewPasswordSchema = z.object({
  code: z.string(),
  newPassword: PasswordSchema,
  confirmNewPassword: PasswordSchema,
});

export type NewPassword = z.infer<typeof NewPasswordSchema>;

type NewPasswordFormProps = {
  code: string;
};

export function NewPasswordForm(props: NewPasswordFormProps) {
  const { code } = props;

  const router = useRouter();
  const onSubmit = async () => {
    // TODO: Integrate
    console.log('TODO: Integrate');
    router.navigate({ to: '/profile/security/password-reset' });
  };

  return (
    <Form<NewPassword>
      schema={NewPasswordSchema}
      defaultValues={{
        code,
      }}
      onSubmit={onSubmit}
    >
      <FormTextInput<NewPassword>
        name="newPassword"
        type="password"
        label="Neues Passwort"
        placeholder="Neues Passwort"
      />
      <FormTextInput<NewPassword>
        name="confirmNewPassword"
        type="password"
        placeholder="Neues Passwort wiederholen"
      />

      <div className="pt-4">
        <Button className="w-full">Neues Passwort speichern</Button>
      </div>
    </Form>
  );
}
