'use client';

import { FileUpload, ImageUploadRequest } from '@clubsoul/api-contracts';
import { UploadAvatar } from '@clubsoul/ui';

import {
  useDeleteUserAvatar,
  useUploadUserAvatar,
} from '@/api/user/useUserAvatar';
import useUser from '@/auth/useUser';
import { uploadToS3 } from '@/lib/api';
import { toast } from 'sonner';

type ProfileAvatarProps = {
  url?: string;
};

export function ProfileAvatar(props: ProfileAvatarProps) {
  const { url } = props;

  const { mutateAsync: uploadAvatar } = useUploadUserAvatar();
  const { mutateAsync: deleteAvatar } = useDeleteUserAvatar();

  const handleAvatarUpload = async (file: File) => {
    let data: FileUpload;

    try {
      data = await uploadAvatar({
        size: file.size,
        mime: file.type as ImageUploadRequest['mime'],
      });
    } catch (error) {
      toast.error('Das Profilbild konnte nicht hochgeladen werden');
      throw error;
    }

    try {
      await uploadToS3({ ...data!, file });
      toast.success('Das Profilbild wurde hochgeladen');
    } catch (error) {
      await deleteAvatar();
      toast.error('Das Profilbild konnte nicht hochgeladen werden');
      throw error;
    }
  };

  const handleDelete = async () => {
    await toast.promise(deleteAvatar(), {
      loading: 'Profilbild wird entfernt...',
      success: 'Das Profilbild wurde entfernt',
      error: 'Das Profilbild konnte nicht entfernt werden',
    });
  };

  return (
    <UploadAvatar
      onUpload={handleAvatarUpload}
      onDelete={handleDelete}
      imgUrl={url}
      fallback={<Fallback />}
    />
  );
}

function Fallback() {
  const { firstName, lastName } = useUser();

  return `${firstName.toUpperCase().charAt(0)}${lastName.toUpperCase().charAt(0)}`;
}
