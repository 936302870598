import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MemberSession } from '@clubsoul/api-contracts';
import { useQueryClient } from '@tanstack/react-query';

import { getSessionQueryOptions } from '@/api/useGetSession';
import { auth } from '@/lib/firebase';
import { signOut } from 'firebase/auth';
import { Loader2 } from 'lucide-react';

import { AuthContext } from './useAuth';

export function AuthProvider({ children }: PropsWithChildren) {
  const [session, setSession] = useState<MemberSession | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isAuthenticated = !!auth.currentUser;
  const queryClient = useQueryClient();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setSession(null);
        setIsLoading(false);
        return;
      }

      const result = await user.getIdTokenResult();

      if (result.claims.clubsoul) {
        if (!session) {
          setIsLoading(true);
        }
        try {
          const session = await queryClient.fetchQuery(getSessionQueryOptions);
          setSession(session);
        } catch (e) {
          await signOut(auth);
        } finally {
          setIsLoading(false);
        }
      } else {
        await signOut(auth);
        setSession(null);
        setIsLoading(false);
      }
    });
  }, [queryClient]);

  const refetchSession = useCallback(async () => {
    try {
      const session = await queryClient.fetchQuery(getSessionQueryOptions);
      setSession(session);
    } catch (e) {
      await signOut(auth);
    }
  }, [queryClient]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      session,
      setSession,
      refetchSession,
    }),
    [isAuthenticated, session, isLoading, refetchSession],
  );

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? (
        <main className="grid h-screen w-screen place-items-center">
          <Loader2 className="animate-spin" />
        </main>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
