'use client';

import { Cross2Icon, UploadIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import Dropzone, {
  type DropzoneProps,
  type FileRejection,
} from 'react-dropzone';
import { toast } from 'sonner';

import { MB } from '@clubsoul/const';
import { useControllableState } from '../hooks';
import { cn, formatBytes } from '../lib/utils';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { ScrollArea } from './ui/scroll-area';

export interface FileUploaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Value of the uploader.
   * @type File[]
   * @default undefined
   * @example value={files}
   */
  value?: File[];

  /**
   * Function to be called when the value changes.
   * @type React.Dispatch<React.SetStateAction<File[]>>
   * @default undefined
   * @example onValueChange={(files) => setFiles(files)}
   */
  onValueChange?: React.Dispatch<React.SetStateAction<File[]>>;

  /**
   * Function to be called when files are uploaded.
   * @type (files: File[]) => Promise<void>
   * @default undefined
   * @example onUpload={(files) => uploadFiles(files)}
   */
  onUpload?: (files: File[]) => Promise<void>;

  /**
   * Progress of the uploaded files.
   * @type Record<string, number> | undefined
   * @default undefined
   * @example progresses={{ "file1.png": 50 }}
   */
  progresses?: Record<string, number>;

  /**
   * Accepted file types for the uploader.
   * @type { [key: string]: string[]}
   * @default
   * ```ts
   * { "image/*": [] }
   * ```
   * @example accept={["image/png", "image/jpeg"]}
   */
  accept?: DropzoneProps['accept'];

  /**
   * Maximum file size for the uploader.
   * @type number | undefined
   * @default 1024 * 1024 * 2 // 2MB
   * @example maxSize={1024 * 1024 * 2} // 2MB
   */
  maxSize?: DropzoneProps['maxSize'];

  /**
   * Maximum number of files for the uploader.
   * @type number | undefined
   * @default 1
   * @example maxFiles={5}
   */
  maxFiles?: DropzoneProps['maxFiles'];

  /**
   * Whether the uploader should accept multiple files.
   * @type boolean
   * @default false
   * @example multiple
   */
  multiple?: boolean;

  /**
   * Whether the uploader is disabled.
   * @type boolean
   * @default false
   * @example disabled
   */
  disabled?: boolean;

  /**
   * ClassName for the parent element.
   */
  parentClassName?: string;

  /**
   * Toggle for hiding the upload button when maxFiles is reached.
   */
  hideUploadOnMaxFiles?: boolean;

  /**
   * Render function for each uploaded file.
   */
  renderFile?: (file: File, index: number) => React.ReactNode;

  /**
   * Render function for all uploaded files.
   */
  renderFiles?: (
    files: File[],
    removeFile: (index: number) => void,
  ) => React.ReactNode;
}

export function FileUploader(props: FileUploaderProps) {
  const {
    value: valueProp,
    onValueChange,
    onUpload,
    progresses,
    accept = { 'image/*': [] },
    maxSize = 2 * MB,
    maxFiles = 1,
    multiple = false,
    disabled = false,
    className,
    parentClassName,
    hideUploadOnMaxFiles = false,
    renderFile = (file, index) => (
      <FileCard
        key={index}
        file={file}
        onRemove={() => onRemove(index)}
        progress={progresses?.[file.name]}
      />
    ),
    renderFiles = (files) => (
      <ScrollArea className="h-fit w-full px-3">
        <div className="max-h-48 space-y-4">
          {files?.map((file, index) => renderFile(file, index))}
        </div>
      </ScrollArea>
    ),
    ...dropzoneProps
  } = props;

  const [files, setFiles] = useControllableState({
    prop: valueProp,
    onChange: onValueChange,
  });

  const onDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!multiple && maxFiles === 1 && acceptedFiles.length > 1) {
        toast.error(
          'Es kann nicht mehr als eine Datei gleichzeitig hochgeladen werden.',
        );
        return;
      }

      if ((files?.length ?? 0) + acceptedFiles.length > maxFiles) {
        toast.error(
          `Es können nicht mehr als ${maxFiles} Dateien hochgeladen werden`,
        );
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      const updatedFiles = files ? [...files, ...newFiles] : newFiles;

      setFiles(updatedFiles);

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(({ file }) => {
          toast.error(`Datei ${file.name} wurde abgelehnt`);
        });
      }

      if (
        onUpload &&
        updatedFiles.length > 0 &&
        updatedFiles.length <= maxFiles
      ) {
        toast.promise(onUpload(updatedFiles), {
          loading:
            updatedFiles.length === 1
              ? `1 Datei wird hochgeladen...`
              : `${updatedFiles.length} Dateien hochladen...`,
          success: () => {
            setFiles([]);
            return updatedFiles.length === 1
              ? '1 Datei wurde hochgeladen'
              : `${updatedFiles.length} Dateien hochgeladen`;
          },
          error:
            updatedFiles.length === 1
              ? '1 Datei konnte nicht hochgeladen werden'
              : `${updatedFiles.length} Dateien konnten nicht hochgeladen werden`,
        });
      }
    },

    [files, maxFiles, multiple, onUpload, setFiles],
  );

  function onRemove(index: number) {
    if (!files) return;
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    onValueChange?.(newFiles);
  }

  // Revoke preview url when component unmounts
  React.useEffect(() => {
    return () => {
      if (!files) return;
      files.forEach((file) => {
        if (isFileWithPreview(file)) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = disabled || (files?.length ?? 0) >= maxFiles;

  return (
    <div
      className={cn(
        'relative flex flex-col gap-6 overflow-hidden',
        parentClassName,
      )}
    >
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        maxSize={maxSize}
        maxFiles={maxFiles}
        multiple={maxFiles > 1 || multiple}
        disabled={isDisabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={cn(
              'group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25',
              'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
              isDragActive && 'border-muted-foreground/50',
              isDisabled && 'pointer-events-none opacity-60',
              hideUploadOnMaxFiles &&
                (files?.length ?? 0) >= maxFiles &&
                'hidden',
              className,
            )}
            {...dropzoneProps}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                <div className="rounded-full border border-dashed p-3">
                  <UploadIcon
                    className="size-7 text-muted-foreground"
                    aria-hidden="true"
                  />
                </div>
                <p className="font-medium text-muted-foreground">
                  Lege die Dateien hier ab
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
                <div className="rounded-full border border-dashed p-3">
                  <UploadIcon
                    className="size-7 text-muted-foreground"
                    aria-hidden="true"
                  />
                </div>
                <div className="space-y-px">
                  <p className="text-sm text-muted-foreground/70">
                    Maximale Dateigröße 5MB {formatBytes(maxSize)}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {(files?.length ?? 0 > 0) ? renderFiles(files!, onRemove) : null}
    </div>
  );
}

interface FileCardProps {
  file: File;
  onRemove: () => void;
  progress?: number;
  renderImage?: (file: File) => React.ReactNode;
}

function FileCard(props: FileCardProps) {
  const { file, progress, onRemove, renderImage } = props;

  return (
    <div className="relative flex items-center space-x-4">
      <div className="flex flex-1 space-x-4">
        {!!renderImage && isFileWithPreview(file) && renderImage(file)}
        <div className="flex w-full flex-col gap-2">
          <div className="space-y-px">
            <p className="line-clamp-1 text-sm font-medium text-foreground/80">
              {file.name}
            </p>
            <p className="text-xs text-muted-foreground">
              {formatBytes(file.size)}
            </p>
          </div>
          {progress ? <Progress value={progress} /> : null}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          type="button"
          variant="outline"
          size="icon"
          className="size-7"
          onClick={onRemove}
        >
          <Cross2Icon className="size-4 " aria-hidden="true" />
          <span className="sr-only">Datei löschen</span>
        </Button>
      </div>
    </div>
  );
}

export function isFileWithPreview(
  file: File,
): file is File & { preview: string } {
  return 'preview' in file && typeof file.preview === 'string';
}
