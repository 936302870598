import { Button, Form, FormTextInput } from '@clubsoul/ui';
import { useRouter } from '@tanstack/react-router';

import { z } from 'zod';

export function ChangePasswordRequestForm() {
  const router = useRouter();
  const onSubmit = async () => {
    // TODO: Integrate
    console.log('TODO: Integrate');
    router.navigate({ to: '/profile/security/password-reset' });
  };

  return (
    <Form
      schema={z.object({ text: z.string() })}
      defaultValues={{ text: '12345678' }}
      onSubmit={onSubmit}
    >
      <FormTextInput name="text" type="password" disabled />

      <Button>Passwort ändern</Button>
    </Form>
  );
}
