import { JoinMemberWaitlistRequest } from '@clubsoul/api-contracts';
import { useMutation } from '@tanstack/react-query';

import { publicClient } from '@/lib/axios';

export const useJoinWaitlist = () => {
  return useMutation({
    mutationFn: (data: JoinMemberWaitlistRequest) =>
      publicClient.post('/v1/members/waitlist/join', data),
  });
};
