import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { LinkButton } from '@/components/link-button';
import { Step } from '@/components/step';
import { HelpCircle, Info, ShieldAlert } from 'lucide-react';

export const Route = createFileRoute('/_app/profile/delete/confirm')({
  component: ProfileDeleteConfirmPage,
});

function ProfileDeleteConfirmPage() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="grow text-center">Account löschen</PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <PageContentTitle className="text-center">
          Sind Sie sicher, dass Sie Ihr Konto löschen möchten?
        </PageContentTitle>
      </PageContent>

      <PageContent className="space-y-8">
        <Step
          icon={ShieldAlert}
          title="1. Datenschutz"
          description="Daten werden nach der Löschung anonymisiert und aus rechtlichen Gründen bis zu 2 Jahre gespeichert."
        />

        <Step
          icon={Info}
          title="2. Hinweis"
          description="Die Löschung der App bedeutet nicht den Austritt aus dem Verein. Bitte wende dich für einen Austritt direkt an den Verein."
        />

        <Step
          icon={HelpCircle}
          title="3. Weitere Fragen"
          description="Bei Fragen wende dich bitte an unseren Support."
        />
      </PageContent>

      <PageContent>
        <LinkButton
          className="w-full"
          variant={'destructive'}
          to="/profile/delete/resend"
        >
          Löschung beantragen
        </LinkButton>
      </PageContent>
    </div>
  );
}
