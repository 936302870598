import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@clubsoul/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { LoginForm } from '@/auth/login-form';
import Header from '@/components/header';
import { Page, PageContent } from '@/components/layout';
import { auth } from '@/lib/firebase';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  component: LoginComponent,
  beforeLoad: async () => {
    await auth.authStateReady();

    if (auth.currentUser) {
      throw redirect({ to: '/' });
    }
  },
});

function LoginComponent() {
  return (
    <>
      <Header>
        <Button asChild variant="outline" size="sm">
          <a href={import.meta.env.VITE_ADMIN_APP_URL}>Club verwalten</a>
        </Button>
      </Header>
      <Page>
        <PageContent className="mt-8 rounded-t-md bg-white px-4">
          <Card>
            <CardHeader>
              <div className="flex justify-center">
                <img src="logo_black.svg" className="mb-8 h-20 w-20" />
              </div>
              <CardTitle>Login</CardTitle>
              <CardDescription>
                Logge dich in der Community App ein
              </CardDescription>
            </CardHeader>

            <CardContent>
              <LoginForm />
            </CardContent>
          </Card>
          <footer className="mt-2">
            <div className="flex flex-wrap gap-2 text-sm text-muted-foreground">
              <a
                href={import.meta.env.VITE_IMPRINT_URL}
                className="cursor-pointer underline-offset-2 hover:underline"
                target="_blank"
              >
                Impressum
              </a>
              <a
                href={import.meta.env.VITE_AGB_URL}
                className="cursor-pointer underline-offset-2 hover:underline"
                target="_blank"
              >
                AGB
              </a>
              <a
                href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                className="cursor-pointer underline-offset-2 hover:underline"
                target="_blank"
              >
                Datenschutz
              </a>
            </div>
          </footer>
        </PageContent>
      </Page>
    </>
  );
}
