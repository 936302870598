import { Club } from '@clubsoul/api-contracts';

export const clubKeys = {
  base: ['club'],
  getWailistPreview: (id: Club['id']) => [
    ...clubKeys.base,
    id,
    'waitlist-preview',
  ],
  getDetails: () => [...clubKeys.base, 'details'],
  getSocialInfo: () => [...clubKeys.base, 'social-info'],
};
