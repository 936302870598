import { VerifyEmailBanner } from '@clubsoul/ui';

import { useResendUserVerification } from '@/api/user/useUserVerification';
import useUser from '@/auth/useUser';

import { PageContent } from './layout';

export function VerifyEmail() {
  const { verified } = useUser();
  const { mutateAsync: resendEmail } = useResendUserVerification();

  if (verified) {
    return null;
  }

  return (
    <PageContent>
      <VerifyEmailBanner resendEmail={resendEmail} />
    </PageContent>
  );
}
