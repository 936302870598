import { useEffect, useRef } from 'react';

import { cn } from '@clubsoul/ui/lib/utils';

import QRCodeStyling from 'qr-code-styling';

export type QRCodeProps = {
  value: string;
  className?: string;
};

const qrCode = new QRCodeStyling({
  width: 256,
  height: 256,
  image: '/logo_black.svg',
  dotsOptions: {
    color: '#000',
    type: 'extra-rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 2,
  },
  qrOptions: {
    errorCorrectionLevel: 'Q',
  },
  type: 'canvas',
});

export function QRCode(props: QRCodeProps) {
  const qrRef = useRef(null);
  const { value, className } = props;

  useEffect(() => {
    qrCode.append(qrRef.current!);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: value,
    });
  }, [value]);

  return (
    <div className={cn('relative h-[256px] w-[256px]', className)}>
      <div ref={qrRef} />
      {/* <ReactQRCode
        value={value}
        size={256}
        bgColor="#ffffff"
        fgColor="#000000"
        level="H"
        includeMargin={false}
        renderAs="svg"
        imageSettings={{
          src: '/logo_black.svg',
          x: undefined,
          y: undefined,
          height: 50,
          width: 50,
          excavate: true,
        }}
      />
      <img
        src={'/logo_black.svg'}
        alt="logo"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50px',
          height: '50px',
          borderRadius: '8px',
        }}
      /> */}
    </div>
  );
}
