import { FieldValues, Path } from 'react-hook-form';

import { SelectItem } from '../../components/ui/select';

import { FormSelect } from './form-select';

type FormCountryPickerProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
};

export function FormCountryPicker<T extends FieldValues>(
  props: FormCountryPickerProps<T>,
) {
  const { name, label = 'Land', className, disabled, required = false } = props;

  return (
    <FormSelect<T>
      name={name}
      label={label}
      placeholder="Land auswählen"
      disabled={disabled}
      required={required}
      className={className}
    >
      <SelectItem value="DE">Deutschland</SelectItem>
    </FormSelect>
  );
}
