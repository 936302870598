import { MemberMembership } from '@clubsoul/api-contracts';
import { MembershipInterval } from '@clubsoul/const';

export function useMembership(id: MemberMembership['id']): MemberMembership {
  return {
    id,
    name: 'Gold',
    price: 200,
    currency: 'EUR',
    startDate: new Date('2024-08-12'),
    expirationDate: new Date('2024-12-12'),
    interval: MembershipInterval.MONTHLY,
    active: true,
    autoRenew: true,
  };
}
