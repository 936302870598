import { MemberMembership } from '@clubsoul/api-contracts';
import { MembershipInterval } from '@clubsoul/const';

export function useMemberships(): MemberMembership[] {
  return [
    {
      id: '1',
      name: 'Gold',
      price: 200,
      currency: 'EUR',
      startDate: new Date('2024-08-12'),
      expirationDate: new Date('2024-12-12'),
      interval: MembershipInterval.MONTHLY,
      active: true,
      autoRenew: true,
    },
    {
      id: '2',
      name: 'Silver',
      price: 100,
      currency: 'EUR',
      startDate: new Date('2024-08-12'),
      expirationDate: new Date('2024-12-12'),
      interval: MembershipInterval.MONTHLY,
      active: true,
      autoRenew: true,
    },
    {
      id: '3',
      name: 'Silver',
      price: 100,
      currency: 'EUR',
      startDate: new Date('2024-08-12'),
      expirationDate: new Date('2024-12-12'),
      interval: MembershipInterval.MONTHLY,
      active: true,
      autoRenew: true,
    },
    {
      id: '4',
      name: 'Silver',
      price: 100,
      currency: 'EUR',
      startDate: new Date('2024-08-12'),
      expirationDate: new Date('2024-12-12'),
      interval: MembershipInterval.MONTHLY,
      active: true,
      autoRenew: true,
    },
    {
      id: '5',
      name: 'Silver',
      price: 100,
      currency: 'EUR',
      startDate: new Date('2024-08-12'),
      expirationDate: new Date('2024-12-12'),
      interval: MembershipInterval.MONTHLY,
      active: true,
      autoRenew: true,
    },
  ];
}
