import { MEMBERSHIPS_ENABLED } from '@clubsoul/const';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { MembershipsList } from '@/components/memberships';

export const Route = createFileRoute('/_app/memberships/')({
  beforeLoad: () => {
    if (!MEMBERSHIPS_ENABLED.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: Page,
});

function Page() {
  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Deine Beiträge
          </PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <MembershipsList />
      </PageContent>
    </div>
  );
}
