import { MEMBERSHIPS_ENABLED } from '@clubsoul/const';
import {
  Alert,
  AlertDescription,
  Badge,
  Card,
  CardTitle,
  RadialChart,
} from '@clubsoul/ui';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { useMembership } from '@/api/membership/useMembership';
import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';
import { MembershipCard } from '@/components/memberships';

export const Route = createFileRoute('/_app/memberships/$id')({
  beforeLoad: () => {
    if (!MEMBERSHIPS_ENABLED.enabled) {
      throw redirect({
        to: '/',
      });
    }
  },
  component: Page,
});

function Page() {
  const { id } = Route.useParams();

  const data = useMembership(id);

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Beitrag
          </PageTitle>
        </div>
      </PageHeader>

      <PageContent>
        <MembershipCard {...data} />
      </PageContent>

      <PageContent>
        <Chart />
      </PageContent>

      <PageContent>
        <Alert variant={'info'}>
          <AlertDescription className="text-center">
            Nächste Zahlung ist fällig am: 11. Mai 2024
          </AlertDescription>
        </Alert>
      </PageContent>

      <Status />

      <Information />
    </div>
  );
}

function Status() {
  return (
    <PageContent>
      <Card className="space-y-8 p-4">
        <div className="space-y-2">
          <CardTitle className="text-base">Status</CardTitle>
          <Badge variant={'active'}>Aktiv</Badge>
        </div>

        <div className="space-y-2">
          <CardTitle className="text-base">Mitglied seit</CardTitle>

          <div>10.02.2024</div>
        </div>
      </Card>
    </PageContent>
  );
}

function Information() {
  return (
    <PageContent>
      <Card className="space-y-2 p-4">
        <CardTitle className="text-base">Weitere Informationen:</CardTitle>

        <div>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.
        </div>
      </Card>
    </PageContent>
  );
}

function Chart() {
  const config = {
    delivered: {
      label: 'Abgegeben',
      color: 'hsl(var(--chart-1))',
    },
    onWay: {
      label: 'Insgesamt',
      color: 'hsl(var(--chart-2))',
    },
  };

  const data = {
    delivered: 15,
    onWay: 10,
  };

  return (
    <RadialChart
      title="Abgabe"
      description={`${data.delivered}g / ${data.delivered + data.onWay}g`}
      note="*Die monatliche Höchstmenge beträgt 50g."
      data={[data]}
      config={config}
    />
  );
}
