import { Button, Card, CardDescription, CardTitle } from '@clubsoul/ui';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

import {
  clubDetailsQueryOptions,
  clubSocialInfoQueryOptions,
} from '@/api/club/clubQueryOptions';
import { BackButton } from '@/components/back-button';
import ClubCard from '@/components/club-card';
import {
  Discord,
  Facebook,
  Instagram,
  Signal,
  Telegram,
  Tiktok,
  Twitter,
  Whatsapp,
  Youtube,
} from '@/components/icons/social';
import {
  PageContent,
  PageContentTitle,
  PageHeader,
  PageTitle,
} from '@/components/layout';
import { ExternalLink, FileText } from 'lucide-react';

export const Route = createFileRoute('/_app/club/')({
  beforeLoad: async ({ context }) => {
    if (context.auth.session?.clubId === undefined) {
      throw redirect({ to: '/' });
    }
  },
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(clubDetailsQueryOptions()),
  component: Page,
});

function Page() {
  const { data } = useSuspenseQuery(clubDetailsQueryOptions());

  const {
    name,
    membersSize,
    description,
    phoneNumber,
    email,
    address,
    logoUrl,
    bylawsUrl,
    preventionPlanUrl,
    membershipRegulationUrl,
    files = [],
  } = data;

  const noDocsShared =
    !bylawsUrl &&
    !preventionPlanUrl &&
    !membershipRegulationUrl &&
    files.length === 0;

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />
          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Dein Club
          </PageTitle>
        </div>
      </PageHeader>

      <PageContent className="space-y-4">
        <ClubCard name={name} logoUrl={logoUrl} membersSize={membersSize} />

        <Card className="space-y-2 p-4">
          <CardTitle className="text-md">Beschreibung</CardTitle>
          <CardDescription className="text-md text-foreground">
            {description}
          </CardDescription>
        </Card>

        <Card className="space-y-2 p-4">
          <CardTitle className="text-md">Kontakt</CardTitle>
          <div className="grid grid-cols-2 gap-2 text-foreground">
            <div className="space-y-2">
              <span className="font-semibold">Telefon</span>
              <a
                className="block underline-offset-4 hover:underline"
                href={`tel:${phoneNumber}`}
              >
                {phoneNumber}
              </a>
            </div>

            <div className="space-y-2">
              <span className="font-semibold">E-Mail</span>
              <a
                className="block underline-offset-4 hover:underline"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </div>
          </div>
        </Card>
      </PageContent>

      <PageContent className="space-y-2">
        <PageContentTitle>Geteilte Dokumente</PageContentTitle>

        {noDocsShared && <p>Keine geteilte Dokumente</p>}

        {bylawsUrl && <Document name="Satzung" url={bylawsUrl} />}
        {preventionPlanUrl && (
          <Document name="Präventionskonzept" url={preventionPlanUrl} />
        )}
        {membershipRegulationUrl && (
          <Document name="Beitragsordnung" url={membershipRegulationUrl} />
        )}

        {files.map((file) => (
          <Document key={file.id} name={file.name} url={file.url} />
        ))}
      </PageContent>

      <PageContent>
        <SocialInfo />
      </PageContent>

      <PageContent>
        <Card className="p-4">
          <div className="space-y-2">
            <h5 className="font-semibold">Agabestelle</h5>
            <p>
              {address.street}, {address.postalCode} {address.city}
            </p>
          </div>
        </Card>
      </PageContent>
    </div>
  );
}

type DocumentProps = {
  name: string;
  url: string;
};

function Document(props: DocumentProps) {
  const { name, url } = props;

  return (
    <Card className="flex items-center gap-2 px-4 py-2">
      <FileText size={20} />
      <a
        href={url}
        className="block underline-offset-4 hover:underline"
        target="_blank"
      >
        {name}
      </a>
    </Card>
  );
}

function SocialInfo() {
  const { data: socialInfo } = useQuery(clubSocialInfoQueryOptions());

  if (!socialInfo) {
    return undefined;
  }

  const { website, ...socialLinks } = socialInfo;

  const {
    facebook,
    instagram,
    youtube,
    twitter,
    discord,
    signal,
    whatsapp,
    tiktok,
    telegram,
  } = socialLinks;

  if (Object.keys(socialInfo).length === 0) {
    return null;
  }

  return (
    <Card className="space-y-4 p-4">
      <div className="space-y-2">
        <h5 className="mb-2 font-semibold">Social & Links</h5>

        {website && (
          <Button asChild variant="outline">
            <a href={website} className="flex items-center gap-2">
              <ExternalLink size={16} />
              <span>Website</span>
            </a>
          </Button>
        )}
      </div>

      {Object.keys(socialLinks).length > 0 ? (
        <div className="space-y-4">
          <h6 className="text-sm font-semibold">Folge uns</h6>
          <div className="flex flex-wrap items-center gap-1">
            {facebook && (
              <Button asChild variant="ghost" size="icon">
                <a href={facebook}>
                  <Facebook />
                </a>
              </Button>
            )}

            {instagram && (
              <Button asChild variant="ghost" size="icon">
                <a href={instagram}>
                  <Instagram />
                </a>
              </Button>
            )}

            {youtube && (
              <Button asChild variant="ghost" size="icon">
                <a href={youtube}>
                  <Youtube />
                </a>
              </Button>
            )}

            {twitter && (
              <Button asChild variant="ghost" size="icon">
                <a href={twitter}>
                  <Twitter />
                </a>
              </Button>
            )}

            {discord && (
              <Button asChild variant="ghost" size="icon">
                <a href={discord}>
                  <Discord />
                </a>
              </Button>
            )}

            {whatsapp && (
              <Button asChild variant="ghost" size="icon">
                <a href={whatsapp}>
                  <Whatsapp />
                </a>
              </Button>
            )}

            {signal && (
              <Button asChild variant="ghost" size="icon">
                <a href={signal}>
                  <Signal />
                </a>
              </Button>
            )}

            {telegram && (
              <Button asChild variant="ghost" size="icon">
                <a href={telegram}>
                  <Telegram />
                </a>
              </Button>
            )}

            {tiktok && (
              <Button asChild variant="ghost" size="icon">
                <a href={tiktok}>
                  <Tiktok />
                </a>
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </Card>
  );
}
