import { SETTINGS_ENABLED } from '@clubsoul/const';
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  CheckboxWithLabel,
} from '@clubsoul/ui';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { useGetSession } from '@/api/useGetSession';
import { userSettingsQueryOptions } from '@/api/user/userQueryOptions';
import { BackButton } from '@/components/back-button';
import { PageContent, PageHeader, PageTitle } from '@/components/layout';

export const Route = createFileRoute('/_app/profile/settings')({
  beforeLoad: () => {
    if (!SETTINGS_ENABLED.enabled) {
      throw redirect({ to: '/' });
    }
  },
  component: ProfileSettingsPage,
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(userSettingsQueryOptions()),
});

function ProfileSettingsPage() {
  const { data } = useSuspenseQuery(userSettingsQueryOptions());
  const { data: session } = useGetSession();
  const { memberId, isClubOwner } = session ?? {};

  return (
    <div className="space-y-8 p-4">
      <PageHeader className="space-y-8">
        <div className="flex items-center">
          <BackButton />

          <PageTitle className="-z-10 ml-[-40px] grow text-center">
            Einstellungen
          </PageTitle>
        </div>
      </PageHeader>

      <PageContent className="space-y-4">
        <Card className="space-y-4 p-4">
          <CardHeader className="space-y-2 p-0">
            <CardTitle className="text-md">Club</CardTitle>
            <CardDescription>
              Konfiguriere die Arten von Club-Benachrichtigungen, die du
              erhalten möchtest.
            </CardDescription>
          </CardHeader>

          {isClubOwner && !!memberId && (
            <CheckboxWithLabel
              id="clubOwner"
              label="Club-Admin-Benachrichtigungen"
              checked={data.allowClubOwnerNotifications}
              // TODO: Integrate with update API
              onChange={() => Promise.resolve()}
            />
          )}
        </Card>
      </PageContent>
    </div>
  );
}
